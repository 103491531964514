import { defineStore } from 'pinia'
import NoticeDataService from '@/services/NoticeDataService'
import { Portals, Portal, View } from '@/common/craftsMasterResolver'
import Craftsman from '@/common/craftsmanResolver'
import JobOrder from '@/common/jobOrderResolver'
import Notice from '@/common/noticeResolver'
import User from '@/common/userResolver'
import { useCraftsMasterStore } from './craftsMasterStore'
import { useUserStore } from './userStore'
import { useCraftsmanStore } from './craftsmanStore'
import { useJobOrderStore } from './jobOrderStore'
import { useEventStore } from './eventStore'
import Chance from 'chance'
import moment from 'moment'

const chance = Chance()
const generalErrorText = 'Unable to update notices--please try again later or contact CraftsMaster for help'

export const useNoticeStore = defineStore('notice', {
	state: () => ({
		notices: [],
		noticeCenterVisited: false
	}),
	getters: {
		noticeCenter: state => {
			const userStore = useUserStore()
			const jobOrderStore = useJobOrderStore()
			const user = userStore.currentUser
			const craftsmanStore = useCraftsmanStore()
			const notices = state.notices
				.filter(n => {
					let includeNotice = false
					const jobOrder = jobOrderStore.jobOrders.find(j => j.JobOrderId === n.JobOrderId)
					switch (user.TypeId) {
						case User.UserType.Employer:
							includeNotice = jobOrder && jobOrder.EmployerId === userStore.currentCompanyId
							break
						case User.UserType.Vendor:
							if (jobOrder) {
								if (userStore.currentCompanyId === n.CreatedByCompanyId ||
									(jobOrder.EmployerId === n.CreatedByCompanyId &&
										((!n.CraftsmanId && jobOrder.Vendors.some(v => v.VendorId === userStore.currentCompanyId)) ||
											jobOrder.Craftsmen?.some(c =>
												c.CraftsmanId === n.CraftsmanId &&
												c.VendorId === userStore.currentCompanyId)))) {
									includeNotice = true
								}
							}
							break
						case User.UserType.Admin:
							includeNotice = true
							break
					}
					return includeNotice
				})
				.map(n => {
					const craftsman = craftsmanStore.craftsmen.find(c => c.CraftsmanId === n.CraftsmanId)
					const createdByCompany = userStore.companies.find(c => c.value === n.CreatedByCompanyId)
					const createdByUser = userStore.users.find(u => u.UserId === n.CreatedByUserId)
					let route = null

					if (n.JobOrderId && (userStore.isEmployer || userStore.isVendor)) {
						const routeName = userStore.isEmployer
							? Portals[Portal.Employer].tabs[View.EmployerJobOrderCraftsmen].name
							: Portals[Portal.Vendor].tabs[View.VendorJobOrderCraftsmen].name
						route = {
							name: routeName,
							params: {
								companyId: userStore.currentCompanyId,
								jobOrderId: n.JobOrderId
							}
						}
					}
					return {
						...n,
						JobOrder: n.JobOrderId || '--',
						Craftsman: craftsman?.Name || '--',
						CreatedByCompany: createdByCompany?.name || '--',
						CreatedByUser: createdByUser?.Name || '--',
						Event: n.EventId || '--',
						Status: n.StatusId ? Craftsman.Statuses[n.StatusId].text : '--',
						Level: Notice.Levels[n.Level].text,
						Notes: n.Notes || '--',
						route,
						routeIcon: route ? JobOrder.IconNavigate : ''
					}
				})
			return notices
		}
	},
	actions: {
		setNoticeCenterVisited (value) {
			this.noticeCenterVisited = value
		},
		async getNotices () {
			this.noticeCenterVisited = false

			try {
				const response = await NoticeDataService.getAll()
				const notices = response?.data
				if (Array.isArray(notices)) {
					this.notices = notices
						.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
						.map(n => {
							let CreatedDate = `<div>${moment(n.createdAt).format('ddd, MMM D YYYY')}</div>`
							CreatedDate += `<div>${moment(n.createdAt).format('h:mm a')}</div>`
							return {
								...n,
								CreatedDate
							}
						})
				} else {
					// eslint-disable-next-line
					console.log(generalErrorText)
				}
			} catch (error) {
				this.notices = []

				// eslint-disable-next-line
				console.log(error.message ? error.message : generalErrorText)
			}
		},
		async addNotice (payload) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const userStore = useUserStore()
				const CreatedByCompanyId = userStore.currentCompanyId
				const CreatedByUserId = userStore.currentUserId
				const notice = {
					NoticeId: chance.hash({ length: 10 }),
					CreatedByCompanyId,
					CreatedByUserId,
					Level: Notice.Level.Low, // default: overridden in payload if extant
					...payload
				}
				const response = await NoticeDataService.create(notice)
				if (response?.data) {
					this.notices.push(response.data)
				} else {
					// eslint-disable-next-line
					console.log('no response from server')
				}
			} catch (error) {
				// eslint-disable-next-line
				console.log(error.response ? error.response.data.message : generalErrorText)
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async addJobOrderNotice (payload) {
			const { jobOrder, craftsmanId, statusId, changes, eventId, level = Notice.Level.Low } = payload

			if (craftsmanId) {
				const joc = jobOrder.Craftsmen.find(c => c.CraftsmanId === craftsmanId)
				if (joc) {
					this.addNotice({
						JobOrderId: jobOrder.JobOrderId,
						CraftsmanId: joc.CraftsmanId,
						StatusId: statusId, // !== null && statusId !== undefined ? statusId : Craftsman.Status.Submitted,
						EventId: eventId,
						Notes: changes,
						Level: level
					})
				}
			} else {
				this.addNotice({
					JobOrderId: jobOrder.JobOrderId,
					Notes: changes
				})
			}
		},
		getJobOrderCraftsmanNotices (payload) {
			const { jobOrderId, craftsmanId, excludeStatuses = [] } = payload
			const eventStore = useEventStore()
			const craftsmanNotices = this.notices.filter(n => {
				if (n.JobOrderId === jobOrderId &&
					n.CraftsmanId === craftsmanId &&
					n.StatusId !== null &&
					!excludeStatuses.includes(n.StatusId)) {
					// don't include notices about events that no longer exist
					if (n.EventId !== null) {
						const eventFound = eventStore.events.some(e => e.EventId === n.EventId)
						return eventFound
					}
					return true
				} else {
					return false
				}
			})

			const high = craftsmanNotices.filter(n => n.Level === Notice.Level.High).sort((a, b) => b.createdAt.localeCompare(a.createdAt))
			const medium = craftsmanNotices.filter(n => n.Level === Notice.Level.Medium).sort((a, b) => b.createdAt.localeCompare(a.createdAt))
			const low = craftsmanNotices.filter(n => n.Level === Notice.Level.Low).sort((a, b) => b.createdAt.localeCompare(a.createdAt))

			return high.concat(medium, low)
		}
	}
})
