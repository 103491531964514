import Chance from 'chance'

const chance = Chance()

const UserType = {
	Employer: 0,
	Vendor: 1,
	Admin: 2,
	Craftsman: 3
}

const UserTypes = [{
	text: 'Employer',
	value: UserType.Employer
}, {
	text: 'Vendor',
	value: UserType.Vendor
}, {
	text: 'Admin',
	value: UserType.Admin
}, {
	text: 'Craftsman',
	value: UserType.Craftsman
}]

const Field = {
	Name: 0,
	Username: 1,
	Passcode: 2,
	Phone: 3,
	Email: 4,
	CompanyId: 5,
	Notes: 6,
	IsAdmin: 7,
	IsPrimary: 8,
	UserId: 9
}

const Fields = [
	{
		id: Field.Name,
		component: 'v-text-field',
		label: 'Name',
		value: null,
		width: '15%',
		required: true,
		sortable: true,
		rando: () => {
			const name = chance.name().split(' ')
			return `${name[0]} ${name[1]}`
		}
	}, {
		id: Field.Username,
		component: 'v-text-field',
		label: 'Username',
		value: null,
		width: '10%',
		required: true,
		sortable: true,
		rando: () => chance.word()
	}, {
		id: Field.Passcode,
		component: 'v-text-field',
		label: 'Passcode',
		value: null
	}, {
		id: Field.Phone,
		component: 'v-text-field',
		label: 'Phone',
		value: null,
		rando: () => chance.phone()
	}, {
		id: Field.Email,
		component: 'v-text-field',
		label: 'Email',
		value: null,
		rando: () => `${chance.word()}@${chance.word()}.com`
	}, {
		id: Field.CompanyId,
		component: 'v-select',
		label: 'Company',
		value: null,
		width: '15%',
		sortable: true,
		groupable: true
	}, {
		id: Field.Notes,
		component: 'v-textarea',
		label: 'Notes',
		width: '13%',
		value: null
	}, {
		id: Field.IsAdmin,
		component: 'v-checkbox',
		label: 'Is Admin',
		width: '8%',
		value: null
	}, {
		id: Field.IsPrimary,
		component: 'v-checkbox',
		label: 'Is Primary User',
		width: '8%',
		value: null
	}, {
		id: Field.UserId
	}
]

const FieldNames = Object.keys(Field)

export const UsersKey = 'craftsmaster:users'

export default {
	Icon: 'mdi-account',
	Field,
	Fields,
	UserType,
	UserTypes,
	FieldNames,
	UsersKey
}
