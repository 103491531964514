import JobOrder from '@/common/jobOrderResolver'
import CraftsMaster from '@/common/craftsMasterResolver'
import Event from '@/common/eventResolver'
import { useVendorStore } from '@/store/vendorStore'
import Chance from 'chance'

const chance = Chance()

// both = applicable to employer & vendor

// *********
// IMPORTANT

// Status === job order craftsman status from a job order's Craftsmen collection

// IMPORTANT
// *********

export const Status = {
	Available: 0, // vendor creates craftsman, default status
	Submitted: 1, // vendor submits craftsman to employer; still available
	InterviewRequested: 2, // both; requests interview with craftsman for specific date/time/duration/location; still available
	InterviewScheduled: 3, // both; employer/vendor accepts the other's event date, time, duration, and location; still available
	OfferExtended: 4, // employer makes offer
	OfferAccepted: 5, // vendor accepts offer
	Onboarding: 6, // vendor schedules event
	OnAssignment: 7, // vendor schedules event
	CompletedAssignment: 8, // automatic when job order expires/completes based on end date
	LeftAssignment: 9, // both
	Terminated: 10, // both
	Rejected: 11, // employer
	Withdrawn: 12 // vendor
}

const Column = {
	Candidate: 0, // both
	Pending: 1, // both
	Completed: 2, // both
	Other: 3 // both
}

export const Statuses = [
	{
		value: Status.Available,
		text: 'Available',
		column: Column.Other,
		available: true
	}, {
		value: Status.Submitted,
		text: 'Submitted',
		column: Column.Candidate,
		available: true
	}, {
		value: Status.InterviewRequested,
		text: 'Interview Requested',
		column: Column.Candidate,
		available: true
	}, {
		value: Status.InterviewScheduled,
		text: 'Interview Scheduled',
		column: Column.Candidate,
		available: true
	}, {
		value: Status.OfferExtended,
		text: 'Offer Extended',
		column: Column.Pending
	}, {
		value: Status.OfferAccepted,
		text: 'Offer Accepted',
		column: Column.Completed
	}, {
		value: Status.Onboarding,
		text: 'Onboarding',
		column: Column.Pending
	}, {
		value: Status.OnAssignment,
		text: 'On Assignment',
		column: Column.Completed
	}, {
		value: Status.CompletedAssignment,
		text: 'Completed Assignment',
		column: Column.Completed
	}, {
		value: Status.LeftAssignment,
		text: 'Left Assignment',
		column: Column.Completed
	}, {
		value: Status.Terminated,
		text: 'Terminated',
		actionText: 'Terminate',
		column: Column.Completed
	}, {
		value: Status.Rejected,
		text: 'Rejected',
		actionText: 'Reject',
		column: Column.Completed
	}, {
		value: Status.Withdrawn,
		text: 'Withdrawn',
		actionText: 'Withdraw',
		column: Column.Completed
	}
]

const Columns = [
	{
		value: Column.Candidate,
		text: 'Candidate',
		color: 'deep-orange'
	}, {
		value: Column.Pending,
		text: 'Pending',
		color: 'green'
	}, {
		value: Column.Completed,
		text: 'Completed',
		color: 'grey'
	}, {
		value: Column.Other,
		text: '',
		color: 'indigo'
	}
]

const Field = {
	Name: 0,
	CraftId: 1,
	ClassId: 2,
	Rate: 3,
	VendorId: 4,
	Address: 5,
	City: 6,
	State: 7,
	Zip: 8,
	Phone: 9,
	Email: 10,
	Resume: 11,
	ResumeFormData: 12,
	Archive: 13,
	CraftsmanId: 14
}

const Fields = [
	{
		id: Field.Name,
		component: 'v-text-field',
		label: 'Name',
		sortable: true,
		width: '13%',
		required: true,
		value: null,
		rando: () => {
			const name = chance.name().split(' ')
			return `${name[0]} ${name[1]}`
		}
	}, {
		id: Field.CraftId,
		component: 'v-select',
		label: 'Craft',
		sortable: true,
		filterPosition: 0,
		value: null,
		groupable: true,
		required: true,
		width: '11%',
		items: [],
		rando: () => chance.integer({ min: 0, max: 18 })
	}, {
		id: Field.ClassId,
		component: 'v-radio-group',
		label: 'Class',
		sortable: true,
		filterPosition: 1,
		value: null,
		groupable: true,
		required: true,
		width: '9%',
		radioItems: [],
		rando: () => chance.integer({ min: 0, max: 2 })
	}, {
		id: Field.Rate,
		component: 'v-text-field',
		label: 'Rate',
		sortable: true,
		prefix: '$',
		type: 'number',
		isCurrency: true,
		required: true,
		width: '5%',
		value: null,
		rando: () => chance.floating({ fixed: 2, min: 25, max: 50 })
	}, {
		id: Field.VendorId,
		component: 'v-select',
		label: 'Vendor',
		value: null,
		hidden: false,
		sortable: true,
		filterPosition: 3,
		groupable: true,
		required: true,
		width: '13%',
		items: []
	}, {
		id: Field.Address,
		component: 'v-text-field',
		label: 'Address',
		type: 'text',
		required: true,
		expanded: true,
		value: null,
		rando: () => chance.address()
	}, {
		id: Field.City,
		component: 'v-text-field',
		label: 'City',
		type: 'text',
		required: true,
		expanded: true,
		value: null,
		rando: () => chance.city()
	}, {
		id: Field.State,
		component: 'v-select',
		label: 'State',
		value: null,
		items: CraftsMaster.StateList,
		required: true,
		expanded: true,
		cols: 4,
		rando: () => chance.state()
	}, {
		id: Field.Zip,
		component: 'v-text-field',
		label: 'Zip',
		sortable: true,
		required: true,
		value: null,
		expanded: true,
		cols: 8,
		rando: () => chance.zip()
	}, {
		id: Field.Phone,
		component: 'v-text-field',
		label: 'Phone',
		required: true,
		expanded: true,
		value: null,
		rando: () => chance.phone()
	}, {
		id: Field.Email,
		component: 'v-text-field',
		sortable: true,
		label: 'Email',
		expanded: true,
		required: true,
		value: null,
		rando: () => `${chance.word()}@${chance.word()}.com`
	}, {
		id: Field.Resume,
		component: 'v-text-field',
		label: 'Resume',
		showSize: true,
		readonly: true,
		prependInnerIcon: 'mdi-paperclip',
		appendOuterIcon: 'mdi-file-upload',
		filterable: false,
		title: 'Drop Resume Below',
		isFileField: true,
		type: 'document',
		width: '4%',
		value: null
	}, {
		id: Field.ResumeFormData,
		value: null,
		type: 'formData',
		hidden: true
	}, {
		id: Field.Archive
	}, {
		id: Field.CraftsmanId
	}
]

const availableStatusIds = [
	Status.Available,
	Status.Submitted,
	Status.InterviewRequested,
	Status.InterviewScheduled,
	Status.Withdrawn
]

const cancelledStatusIds = [
	Status.LeftAssignment,
	Status.Terminated,
	Status.Rejected,
	Status.Withdrawn
]
const eventStatusIds = [
	Status.InterviewRequested,
	Status.InterviewScheduled,
	Status.Onboarding,
	Status.OnAssignment
]

const offerStatusIds = [
	Status.OfferExtended,
	Status.OfferAccepted
]

const FieldNames = Object.keys(Field)

export default {
	Icon: 'mdi-account-hard-hat',
	Status,
	Statuses,
	Column,
	Columns,
	Field,
	Fields,
	FieldNames,
	getFields () {
		const vendorStore = useVendorStore()
		let vendors = JSON.parse(JSON.stringify(vendorStore.vendors))
		vendors = vendors
			.map(v => ({
				text: v.Name,
				value: v.VendorId,
				VendorId: v.VendorId
			}))
			.sort((a, b) => {
				return ('' + a.text).localeCompare(b.text)
			})

		const fields = Fields.map(f => {
			switch (f.id) {
				case Field.VendorId:
					return Object.assign({}, f, {
						items: vendors
					})
				case Field.CraftId:
					return Object.assign({}, f, {
						items: JobOrder.Crafts
					})
				case Field.ClassId:
					return Object.assign({}, f, {
						radioItems: JobOrder.Classes
					})
				default:
					return { ...f }
			}
		})
		return fields
	},
	getStatusFromEvent (event) {
		let statusId = null
		switch (event.TypeId) {
			case Event.Type.Interview:
			case Event.Type.Testing:
				if (event.StatusId === Event.Status.Pending) {
					statusId = Status.InterviewRequested
				} else {
					statusId = Status.InterviewScheduled
				}
				break
			case Event.Type.Assignment:
				statusId = Status.OnAssignment
				break
			case Event.Type.Onboarding:
				statusId = Status.Onboarding
				break
		}
		return statusId
	},

	// *********
	// IMPORTANT

	// status => the job order craftsman's status on a particular job order, not his overall status

	// IMPORTANT
	// *********

	AvailableStatusIds: availableStatusIds,
	CancelledStatusIds: cancelledStatusIds,
	EventStatusIds: eventStatusIds,
	OfferStatusIds: offerStatusIds,

	isAvailable (status) {
		return availableStatusIds.includes(status)
	},
	isUnavailable (status) {
		return status === Status.OfferExtended ||
			status === Status.OfferAccepted ||
			status === Status.Onboarding ||
			status === Status.OnAssignment
	},
	isEventStatus (status) {
		return eventStatusIds.includes(status)
	},
	isOfferStatus (status) {
		return offerStatusIds.includes(status)
	},
	isEmployerCancelledStatus (status) {
		return status === Status.LeftAssignment ||
			status === Status.Terminated ||
			status === Status.Rejected
	},
	isVendorCancelledStatus (status) {
		return status === Status.LeftAssignment ||
			status === Status.Terminated ||
			status === Status.Withdrawn
	},
	isOnAssignment (status) {
		return status === Status.OnAssignment ||
			status === Status.Onboarding
	},
	canSubmit (status) {
		return status === Status.Available ||
			status === Status.CompletedAssignment ||
			status === Status.LeftAssignment ||
			status === Status.Withdrawn
		// Terminated: 14, // both
	},
	canScheduleOnboarding (status) {
		return status === Status.OfferAccepted ||
			status === Status.Onboarding
	},
	canExtendOffer (status) {
		return status === Status.Submitted ||
			status === Status.InterviewRequested ||
			status === Status.InterviewScheduled ||
			status === Status.CompletedAssignment ||
			status === Status.OfferExtended ||
			status === Status.OfferAccepted ||
			status === Status.LeftAssignment
	},
	getVendorCraftsmanFields () {
		const fields = JSON.parse(JSON.stringify(Fields))
		fields[Field.VendorId].hidden = true

		return fields
	},
	getStatusIdByStatusName (name) {
		return Statuses.find(s => s.text === name).value
	}
}
