import axios from 'axios'
import CraftsMaster from '@/common/craftsMasterResolver'

let baseURL = 'https://craftsmaster.net/api'

if (CraftsMaster.DataSource === 'local') {
	baseURL = 'http://localhost:8080/api'
}

export const http = axios.create({
	baseURL,
	headers: {
		'Content-type': 'application/json'
	}
})

export const Direction = {
	Ascending: true,
	Descending: false
}

export function columnSort (a, b, sortConfig) {
	const field = sortConfig.field
	const direction = sortConfig.direction
	if (field) {
		const fieldName = sortConfig.fieldNames[field.id]
		const c = Object.assign({}, a)
		a = direction === Direction.Ascending ? a : b
		b = direction === Direction.Ascending ? b : c
		if (field.type === 'number') {
			return a[fieldName] - b[fieldName]
		} else if (field.items) {
			if (typeof a[fieldName] === 'string') {
				const itemA = field.items.find(i => i.value === a[fieldName])
				const itemB = field.items.find(i => i.value === b[fieldName])
				if (itemA) {
					return ('' + itemA.text).localeCompare(itemB.text)
				} else {
					return ('' + a[fieldName]).localeCompare(b[fieldName])
				}
			} else {
				return ('' + field.items[a[fieldName]].text).localeCompare(field.items[b[fieldName]].text)
			}
			// } else if (field.radioItems) {
			// 	return ('' + field.items[a[index]][field.itemsSortProperty]).localeCompare(field.items[b[index]][field.itemsSortProperty])
		} else {
			return ('' + a[fieldName]).localeCompare(b[fieldName])
		}
	} else {
		return 0
	}
}

export function filterList (config) {
	const list = JSON.parse(JSON.stringify(config.list))
	const filter = JSON.parse(JSON.stringify(config.filter))

	if (!filter || (Array.isArray(filter) && filter.length === 0)) {
		return list
	}

	const filteredList = []

	// gotta sort by filterPosition so filter array entries will line up
	const filterFields = config.fields.filter(f => f.hasOwnProperty('filterPosition')).sort((a, b) => a.filterPosition - b.filterPosition)

	list.forEach(item => {
		let itemIncluded = true
		// do we have an active filter?
		if (filter.some(f => f && f.length)) {
			const isIncluded = []

			// determine if the current item meets the filter criteria found in the filtered fields
			filterFields.forEach((ff, i) => {
				isIncluded[i] = false
				// does the filter, containing filter results, have a matching value with the
				// current item's matching field?
				if (filter[i] && filter[i].length) {
					const fieldValue = item[config.fieldNames[ff.id]]
					let found = false
					if (Array.isArray(fieldValue)) {
						found = !!filter[i].find(result => !!fieldValue.find(fv => fv.value === result))
					} else {
						found = filter[i].includes(fieldValue)
					}
					if (found) {
						isIncluded[i] = true
						return true
					}
				} else {
					isIncluded[i] = true
				}
			})

			itemIncluded = isIncluded.reduce((init, val) => init && val)
		}

		if (itemIncluded) {
			const copy = JSON.parse(JSON.stringify(item))
			filteredList.push(copy)
		}
	})

	return filteredList
}

export function numericKeyDown (event) {
	if (isNaN(event.key) && event.key !== 'Backspace' && event.key.substr(0, 5) !== 'Arrow' && event.key !== 'Delete') {
		event.preventDefault()
	}
}

export function wordSplit (title) {
	const words = []
	if (title) {
		const text = title.split(' ')
		text.forEach(t => {
			words.push({
				firstLetter: t[0],
				minusFirstLetter: t.slice(1)
			})
		})
	}
	return words
}

export function formatCurrencyToString (currency) {
	if (!isNaN(currency)) {
		return currency > 0 ? `$ ${parseFloat(currency).toFixed(2).toString()}` : '--'
	} else {
		return '--'
	}
}
