import { defineStore } from 'pinia'
import JobOrder from '@/common/jobOrderResolver'
import { filterList } from '@/common/utils'
import Craftsman from '@/common/craftsmanResolver'
import { Portals, Portal, View } from '@/common/craftsMasterResolver'
import User from '@/common/userResolver'
import Employer from '@/common/employerResolver'
import Notice from '@/common/noticeResolver'
import JobOrderDataService from '@/services/JobOrderDataService'
import { useEmployerStore } from './employerStore'
import { useVendorStore } from './vendorStore'
import { useCraftsmanStore } from './craftsmanStore'
import { useCraftsMasterStore } from '@/store/craftsMasterStore'
import { useUserStore } from '@/store/userStore'
import { useNoticeStore } from '@/store/noticeStore'
import Chance from 'chance'
import Vue from 'vue'
import moment from 'moment'

const chance = Chance()
const hashLength = 3

function getJobOrderChanges (currentJobOrder, updatedJobOrder) {
	const FieldNames = JobOrder.FieldNames
	const Field = JobOrder.Field
	const Fields = JobOrder.Fields
	const changes = []
	for (const field in Field) {
		if (field !== FieldNames[Field.Vendors] &&
			field !== FieldNames[Field.Craftsmen] &&
			field !== FieldNames[Field.createdAt] &&
			field !== FieldNames[Field.updatedAt] &&
			updatedJobOrder[field] !== currentJobOrder[field]) {
			let value = updatedJobOrder[field]
			if (Fields[Field[field]].isDate) {
				value = moment(value).utc().format('MMM D YYYY')
			} else if (field === FieldNames[Field.YardAreaId]) {
				const yard = Employer.getYardInfo(updatedJobOrder)
				value = `${yard.Name}, ${yard.Area}`
			}
			changes.push(`<div>${Fields[Field[field]].label}: <span class="font-weight-bold">${value}</span></div>`)
		}
	}
	return changes.join('')
}

function getJobOrderCraftsmanChanges ({ jobOrder, craftsmanId, statusId }) {
	let changes = ''

	if (statusId === Craftsman.Status.OfferExtended || statusId === Craftsman.Status.OfferAccepted) {
		if (jobOrder) {
			const joc = jobOrder.Craftsmen.find(c => c.CraftsmanId === craftsmanId)
			if (joc && joc.BillRate) {
				const billRate = joc.BillRate && !isNaN(joc.BillRate) ? parseFloat(joc.BillRate).toFixed(2) : 0
				changes = `<div>Bill Rate: <span class="font-weight-bold">$ ${billRate}</span></div>`
			} else {
				changes = '<div>No offer information found</div>'
			}
		} else {
			changes = '<div>No offer information found</div>'
		}
	} else {
		changes = '<div>Craftsman status changed</div>'
	}
	return changes
}

export const useJobOrderStore = defineStore('jobOrder', {
	state: () => ({
		testJobOrder: {},
		jobOrders: [],
		currentJobOrderId: null,
		jobOrderFilter: [],
		filteredJobOrders: [],
		showInactiveJobOrders: false,
		includeArchivedJobOrders: false,
		jobOrderView: 'card'
	}),
	getters: {
		currentJobOrder: state => state.jobOrders.find(j => j.JobOrderId === state.currentJobOrderId),
		employerAllJobOrdersUnmapped: state => {
			const employerId = useEmployerStore().currentEmployerId
			return state.jobOrders.filter(j => j.EmployerId === employerId)
		},
		employerJobOrders (state) {
			const employerId = useEmployerStore().currentEmployerId
			const jobOrders = this.employerAllJobOrdersUnmapped
				.filter(j =>
					(state.showInactiveJobOrders || moment(j.EndDate).isSameOrAfter()) &&
					((!j.Archived && !state.includeArchivedJobOrders) ||
						(j.Archived && state.includeArchivedJobOrders)))
				.map(j => {
					const statusId = JobOrder.getStatus(j)
					const column = JobOrder.Statuses[statusId].eColumn
					return {
						...j,
						Craft: JobOrder.Crafts[j.CraftId].text,
						Class: JobOrder.Classes[j.ClassId].altText,
						YardArea: JobOrder.getJobTitle(j).yardTwoLine,
						// PayRate: `$ ${j.PayRate.toFixed(2)}`,
						name: JobOrder.getJobTitle(j).craftClassJobOrderId,
						statusId,
						endDate: moment(j.EndDate).utc().format('MMM D, YYYY'),
						updatedDate: moment(j.updatedAt).format('MMM D, YYYY'),
						updatedTime: moment(j.updatedAt).format('h:mm a'),
						submittedCount: j.Craftsmen ? j.Craftsmen.length : 0,
						route: {
							name: Portals[Portal.Employer].tabs[View.EmployerJobOrderCraftsmen].name,
							params: {
								companyId: employerId,
								jobOrderId: j.JobOrderId
							}
						},
						routeIcon: JobOrder.IconNavigate,
						column: JobOrder.Columns[column].text
					}
				})
			return jobOrders
		},
		vendorAllJobOrdersUnmapped: state => {
			const vendorId = useVendorStore().currentVendorId
			return state.jobOrders
				.filter(j => Array.isArray(j.Vendors) && j.Vendors.some(v => v.VendorId === vendorId))
		},
		vendorJobOrders (state) {
			const vendorId = useVendorStore().currentVendorId
			const employerStore = useEmployerStore()
			const userStore = useUserStore()
			return this.vendorAllJobOrdersUnmapped
				.filter(j => (state.showInactiveJobOrders || moment(j.EndDate).isSameOrAfter()))
				// !j.Archived
				.map(j => {
					const employer = employerStore.employers.find(e => e.EmployerId === j.EmployerId)
					const employerUser = userStore.users.find(u => u.CompanyId === employer.EmployerId && u.IsPrimary)
					return {
						...j,
						Craft: JobOrder.Crafts[j.CraftId].text,
						Class: JobOrder.Classes[j.ClassId].altText,
						YardArea: JobOrder.getJobTitle(j).yardTwoLine,
						BillRate: `$ ${j.BillRateLo.toFixed(2)} - ${j.BillRateHi.toFixed(2)}`,
						PayRate: j.PayRate && !isNaN(j.PayRate) ? parseFloat(j.PayRate).toFixed(2) : 0,
						route: {
							name: Portals[Portal.Vendor].tabs[View.VendorJobOrderCraftsmen].name,
							params: {
								companyId: vendorId,
								jobOrderId: j.JobOrderId
							}
						},
						routeIcon: JobOrder.IconNavigate,
						routeTitle: 'To Job Order',
						Employer: employer ? employer.Name : '',
						isInactive: moment(j.EndDate).isBefore(),
						submittedCount: j.Craftsmen ? j.Craftsmen.filter(joc => joc.VendorId === vendorId).length : 0,
						contactInfo: {
							company: employer.Name,
							name: employerUser?.Name,
							email: employerUser?.Email,
							phone: employerUser?.Phone,
							address: employer.Address,
							city: employer.City,
							state: employer.State,
							zip: employer.Zip
						}
					}
				})
		},
		craftsmanJobOrders (state) {
			const craftsmanStore = useCraftsmanStore()
			const employerStore = useEmployerStore()
			const craftsmanJobOrders = state.jobOrders
				.filter(j => {
					if (Array.isArray(j.Craftsmen) && j.Craftsmen.length) {
						const index = j.Craftsmen.findIndex(c => c.CraftsmanId === craftsmanStore.currentCraftsmanId)
						return index !== -1
					} else {
						return false
					}
				})
				.map(j => {
					const employer = employerStore.employers.find(e => e.EmployerId === j.EmployerId)
					const joc = j.Craftsmen.find(joc => joc.CraftsmanId === craftsmanStore.currentCraftsmanId)
					return {
						...j,
						Archived: false, // only for employers at the job order level
						Employer: employer ? employer.Name : '',
						Craft: JobOrder.Crafts[j.CraftId].text,
						Class: JobOrder.Classes[j.ClassId].altText,
						YardArea: JobOrder.getJobTitle(j).yardTwoLine,
						Status: Craftsman.Statuses[joc.StatusId].text,
						PayRate: j.PayRate && !isNaN(j.PayRate) ? parseFloat(j.PayRate).toFixed(2) : 0,
						route: {
							name: Portals[Portal.Vendor].tabs[View.VendorJobOrderCraftsmen].name,
							params: {
								companyId: joc.VendorId,
								jobOrderId: j.JobOrderId
							}
						},
						routeTitle: 'To Job Order',
						routeIcon: JobOrder.IconNavigate
					}
				})
			return craftsmanJobOrders
		},
		adminJobOrders (state) {
			const employerStore = useEmployerStore()
			const jobOrders = state.jobOrders
				.map(j => {
					const employer = employerStore.employers.find(e => e.EmployerId === j.EmployerId)
					return {
						...j,
						Craft: JobOrder.Crafts[j.CraftId].text,
						Class: JobOrder.Classes[j.ClassId].altText,
						YardArea: JobOrder.getJobTitle(j).yardTwoLine,
						BillRate: `$ ${j.BillRateLo.toFixed(2)} - ${j.BillRateHi.toFixed(2)}`,
						PayRate: j.PayRate && !isNaN(j.PayRate) ? parseFloat(j.PayRate).toFixed(2) : 0,
						Employer: employer ? employer.Name : ''
					}
				})
			return jobOrders
		},
		jobOrderCraftsmen () {
			return jobOrder => {
				const craftsmanStore = useCraftsmanStore()
				jobOrder = jobOrder || this.currentJobOrder

				const jobOrderCraftsmen = []
				if (craftsmanStore.craftsmen.length && jobOrder && Array.isArray(jobOrder.Craftsmen) && jobOrder.Craftsmen.length) {
					const vendorStore = useVendorStore()
					const userStore = useUserStore()
					craftsmanStore.craftsmen
						.filter(c => !c.Archived)
						.forEach(c => {
							const joc = jobOrder.Craftsmen.find(joc => c.CraftsmanId === joc.CraftsmanId)
							if (joc) {
								let vendor = vendorStore.vendors.find(v => v.VendorId === joc.VendorId)
								vendor = vendor || { Name: '', VendorId: null }
								const vendorUser = userStore.users.find(u => u.CompanyId === vendor.VendorId && u.IsPrimary)
								const craftsman = Object.assign({}, c)
								delete craftsman.updatedAt
								jobOrderCraftsmen.push({
									...joc,
									...craftsman,
									Vendor: vendor.Name,
									VendorId: vendor.VendorId,
									Craft: JobOrder.Crafts[craftsman.CraftId].text,
									Class: JobOrder.Classes[craftsman.ClassId].altText,
									Rate: craftsman.Rate && !isNaN(craftsman.Rate) ? parseFloat(craftsman.Rate).toFixed(2) : 0,
									Status: Craftsman.Statuses[joc.StatusId].text,
									JobOrderId: jobOrder.JobOrderId,
									StartDate: joc.StartDate || jobOrder.StartDate,
									BillRate: joc.hasOwnProperty('BillRate') ? joc.BillRate : 0,
									contactInfo: {
										craftsmanId: craftsman.CraftsmanId,
										company: vendor.Name,
										name: vendorUser?.Name,
										email: vendorUser?.Email,
										phone: vendorUser?.Phone,
										address: vendor.Address,
										city: vendor.City,
										state: vendor.State,
										zip: vendor.Zip
									}
								})
							}
						})
				}
				return jobOrderCraftsmen
			}
		},
		employerJobOrderCraftsmen () {
			return this.jobOrderCraftsmen().filter(joc => joc.StatusId !== Craftsman.Status.Available)
		},
		vendorJobOrderCraftsmen () {
			const jobOrder = this.currentJobOrder
			const craftsmen = useCraftsmanStore().craftsmen

			const vendor = useVendorStore().currentVendor

			const vjoc = []
			const vendorCraftsmanIds = new Set() // avoiding dups and more efficient lookup
			const unavailableVendorCraftsmanIds = new Set()

			if (jobOrder) {
				// firth, add all current job order vendor craftsmen with their respective status
				jobOrder.Craftsmen
					.filter(joc => joc.VendorId === vendor.VendorId)
					.forEach(joc => {
						const craftsman = craftsmen.find(c => c.CraftsmanId === joc.CraftsmanId && !c.Archived)

						if (craftsman) {
							vjoc.push({
								...joc,
								...craftsman,
								Vendor: vendor.Name,
								VendorId: vendor.VendorId,
								Craft: JobOrder.Crafts[craftsman.CraftId].text,
								Class: JobOrder.Classes[craftsman.ClassId].altText,
								Rate: craftsman.Rate && !isNaN(craftsman.Rate) ? parseFloat(craftsman.Rate).toFixed(2) : 0,
								Status: Craftsman.Statuses[joc.StatusId].text,
								JobOrderId: jobOrder.JobOrderId,
								StartDate: joc.StartDate || jobOrder.StartDate,
								BillRate: joc.hasOwnProperty('BillRate') ? joc.BillRate : 0,
								otherJobOrderData: [],
								contactInfo: {
									craftsmanId: craftsman.CraftsmanId,
									name: craftsman.Name,
									email: craftsman.Email,
									phone: craftsman.Phone,
									address: craftsman.Address,
									city: craftsman.City,
									state: craftsman.State,
									zip: craftsman.Zip
								}
							})

							vendorCraftsmanIds.add(craftsman.CraftsmanId)
						}
					})

				// secondariously, pass thru every other job order and add vendor joc-men with the same craft as the current job order,
				// *** in available status ***, handling duplicate craftsmen since an available craftsman may appear on multiple job orders
				this.vendorJobOrders
					.filter(j =>
						j.JobOrderId !== jobOrder.JobOrderId &&
						j.CraftId === jobOrder.CraftId &&
						Array.isArray(j.Craftsmen) && j.Craftsmen.length
					)
					.forEach(j => {
						j.Craftsmen
							.filter(joc => joc.VendorId === vendor.VendorId)
							.forEach(joc => {
								if (Craftsman.isUnavailable(joc.StatusId)) {
									unavailableVendorCraftsmanIds.add(joc.CraftsmanId)
								}
								if (vendorCraftsmanIds.has(joc.CraftsmanId)) {
									// store job order data in a collection within each vjoc instead
									// of creating another vjoc with the same joc from another job order
									const existingVjoc = vjoc.find(evjoc => evjoc.CraftsmanId === joc.CraftsmanId)
									if (existingVjoc) {
										existingVjoc.otherJobOrderData.push({
											CraftsmanId: joc.CraftsmanId,
											JobOrderId: j.JobOrderId,
											Name: JobOrder.getJobTitle(j).nameThreeLineFormatted,
											StatusId: joc.StatusId,
											Status: Craftsman.Statuses[joc.StatusId].text,
											route: {
												name: Portals[Portal.Vendor].tabs[View.VendorJobOrderCraftsmen].name,
												params: {
													companyId: vendor.VendorId,
													jobOrderId: j.JobOrderId
												}
											}
										})
									}
								} else {
									const craftsman = craftsmen.find(c => c.CraftsmanId === joc.CraftsmanId)
									if (craftsman) {
										vjoc.push({
											...joc,
											...craftsman,
											Vendor: vendor.Name,
											VendorId: vendor.VendorId,
											Craft: JobOrder.Crafts[craftsman.CraftId].text,
											Class: JobOrder.Classes[craftsman.ClassId].altText,
											Rate: craftsman.Rate && !isNaN(craftsman.Rate) ? parseFloat(craftsman.Rate).toFixed(2) : 0,
											updatedAt: null,
											JobOrderId: jobOrder.JobOrderId,
											StartDate: joc.StartDate || j.StartDate,
											StatusId: Craftsman.Status.Available,
											Status: Craftsman.Statuses[Craftsman.Status.Available].text,
											BillRate: 0,
											otherJobOrderData: [{
												CraftsmanId: joc.CraftsmanId,
												JobOrderId: jobOrder.JobOrderId,
												Name: JobOrder.getJobTitle(j).nameThreeLineFormatted,
												StatusId: joc.StatusId,
												Status: Craftsman.Statuses[joc.StatusId].text,
												route: {
													name: Portals[Portal.Vendor].tabs[View.VendorJobOrderCraftsmen].name,
													params: {
														companyId: vendor.VendorId,
														jobOrderId: jobOrder.JobOrderId
													}
												}
											}],
											contactInfo: {
												craftsmanId: craftsman.CraftsmanId,
												name: craftsman.Name,
												email: craftsman.Email,
												phone: craftsman.Phone,
												address: craftsman.Address,
												city: craftsman.City,
												state: craftsman.State,
												zip: craftsman.Zip
											}
										})
									}
								}

								// go ahead and log all craftsmen even if not added to this vjoc-men list to avoid adding them below
								vendorCraftsmanIds.add(joc.CraftsmanId)
							})
					})

				// financialousness, add vendor c-men not yet on any job orders with same craft Id as current job order
				craftsmen
					.filter(c => c.VendorId === vendor.VendorId && c.CraftId === jobOrder.CraftId && !c.Archived)
					.forEach(craftsman => {
						if (!vendorCraftsmanIds.has(craftsman.CraftsmanId)) {
							vjoc.push({
								...craftsman,
								updatedAt: null,
								Vendor: vendor.Name,
								VendorId: vendor.VendorId,
								Craft: JobOrder.Crafts[craftsman.CraftId].text,
								Class: JobOrder.Classes[craftsman.ClassId].altText,
								Rate: craftsman.Rate && !isNaN(craftsman.Rate) ? parseFloat(craftsman.Rate).toFixed(2) : 0,
								JobOrderId: jobOrder.JobOrderId,
								Status: Craftsman.Statuses[Craftsman.Status.Available].text,
								StartDate: jobOrder.StartDate,
								StatusId: Craftsman.Status.Available,
								BillRate: jobOrder.hasOwnProperty('BillRate') ? jobOrder.BillRate : 0,
								otherJobOrderData: [],
								contactInfo: {
									craftsmanId: craftsman.CraftsmanId,
									name: craftsman.Name,
									email: craftsman.Email,
									phone: craftsman.Phone,
									address: craftsman.Address,
									city: craftsman.City,
									state: craftsman.State,
									zip: craftsman.Zip
								}
							})

							vendorCraftsmanIds.add(craftsman.CraftsmanId)
						}
					})
			}

			// remove unavailableVendorCraftsmanIds
			unavailableVendorCraftsmanIds.forEach(id => {
				const index = vjoc.findIndex(joc => joc.CraftsmanId === id)
				if (index !== -1) {
					vjoc.splice(index, 1)
				}
			})

			return vjoc
		},
		employerArchivedJobOrders: state => {
			const employerId = useEmployerStore().currentEmployerId
			return state.jobOrders.filter(j => j.Archived && j.EmployerId === employerId)
		},
		employerInactiveJobOrders () {
			return this.employerAllJobOrdersUnmapped.filter(j => moment(j.EndDate).isBefore())
		},
		vendorInactiveJobOrders () {
			return this.vendorAllJobOrdersUnmapped.filter(j => moment(j.EndDate).isBefore())
		},
		uniqueCraftIds () {
			return [...new Set(this.employerJobOrders.map(j => j.CraftId))]
		},
		uniqueClassIds () {
			return [...new Set(this.employerJobOrders.map(j => j.ClassId))]
		},
		uniqueYardAreaIds () {
			return [...new Set(this.employerJobOrders.map(j => j.YardAreaId))]
		},
		uniqueVendorIds () {
			const vendors = []
			this.employerJobOrders.forEach(o => {
				if (Array.isArray(o.Vendors)) {
					vendors.push(...o.Vendors.map(v => v.VendorId))
				}
			})
			return [...new Set(vendors)]
		},
		uniqueStatusIds () {
			return [...new Set(this.employerJobOrders.map(j => j.statusId))]
		},
		areJobOrdersFiltered: state => state.jobOrderFilter.some(f => f && f.length)
	},
	actions: {
		clearJobOrders () {
			this.jobOrders = []
		},
		async getJobOrdersByUser (user) {
			this.jobOrders = []

			const userStore = useUserStore()
			user = user || userStore.currentUser

			let response
			if (user.TypeId === User.UserType.Vendor) {
				response = await JobOrderDataService.getByVendorId(user.CompanyId)
			} else if (user.TypeId === User.UserType.Employer) {
				response = await JobOrderDataService.getByEmployerId(user.CompanyId)
			} else if (user.TypeId === User.UserType.Admin) {
				response = await JobOrderDataService.getAll()
			}

			if (response) {
				this.jobOrders = response.data.map(j => {
					j.BillRateLo = j.BillRate[0]
					j.BillRateHi = j.BillRate[1]
					delete j.BillRate
					return j
				})
			}

			useCraftsMasterStore().setCurrentAnchor(null)
		},
		async getJobOrderById (id) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await JobOrderDataService.getById(id)
				if (response && response.data) {
					const index = this.jobOrders.findIndex(j => j.JobOrderId === response.data.JobOrderId)
					if (index === -1) {
						const jobOrder = response.data
						jobOrder.BillRateLo = jobOrder.BillRate[0]
						jobOrder.BillRateHi = jobOrder.BillRate[1]
						delete jobOrder.BillRate
						this.jobOrders.push(jobOrder)
					}
					this.setCurrentJobOrderId(response.data.JobOrderId)
				} else {
					this.currentJobOrderId = null
				}
			} catch (error) {
				this.currentJobOrderId = null
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async saveJobOrder (jobOrder) {
			const employer = useEmployerStore().currentEmployer
			jobOrder.EmployerId = employer?.EmployerId || jobOrder.EmployerId

			jobOrder.BillRate = [jobOrder.BillRateLo, jobOrder.BillRateHi]
			delete jobOrder.BillRateLo
			delete jobOrder.BillRateHi

			jobOrder.YardAreaId = jobOrder.YardAreaId.value ?? jobOrder.YardAreaId

			if (jobOrder.hasOwnProperty('JobOrderId') && jobOrder.JobOrderId) {
				await this.updateJobOrder(jobOrder)
			} else {
				await this.addJobOrder(jobOrder)
			}
		},
		async addJobOrder (jobOrder) {
			const employer = useEmployerStore().employers.find(e => e.EmployerId === jobOrder.EmployerId)
			const hash = chance.hash({ length: hashLength, casing: 'upper' })
			const jobOrderId = `${JobOrder.Crafts[jobOrder.CraftId].code}${JobOrder.Classes[jobOrder.ClassId].code}-${employer.EmployerCode}-${hash}`
			jobOrder.JobOrderId = jobOrderId

			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await JobOrderDataService.create(jobOrder)
				if (response) {
					const jobOrder = response.data
					jobOrder.BillRateLo = jobOrder.BillRate[0]
					jobOrder.BillRateHi = jobOrder.BillRate[1]
					delete jobOrder.BillRate
					this.jobOrders.push(jobOrder)

					craftsMasterStore.setSnackbar({
						value: true,
						text: `Job order (${jobOrder.JobOrderId}) added.`
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: 'Job order not added.'
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error?.response?.data ? error.response.data.message : error.message
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async updateJobOrder (jobOrder, options) {
			const craftsMasterStore = useCraftsMasterStore()
			options = options || {
				showLoadingSpinner: true
			}

			if (options.showLoadingSpinner) {
				craftsMasterStore.loading = true
			}

			try {
				if (!Array.isArray(jobOrder.Vendors) || jobOrder.Vendors.length === 0) {
					jobOrder.Posted = false
				}
				const newArchived = !!jobOrder.Archived

				const response = await JobOrderDataService.update(jobOrder.JobOrderId, jobOrder)
				if (response && response.data) {
					const updatedJobOrder = response.data
					const index = this.jobOrders.findIndex(j => j.JobOrderId === updatedJobOrder.JobOrderId)
					if (index !== -1) {
						const oldArchived = !!this.jobOrders[index].Archived

						updatedJobOrder.BillRateLo = updatedJobOrder.BillRate[0]
						updatedJobOrder.BillRateHi = updatedJobOrder.BillRate[1]
						delete updatedJobOrder.BillRate

						const jobOrderChanges = getJobOrderChanges(this.jobOrders[index], updatedJobOrder)

						Vue.set(this.jobOrders, index, updatedJobOrder)

						craftsMasterStore.setSnackbar({
							value: true,
							text: `Job Order (${updatedJobOrder.JobOrderId}) updated`
						})

						// only send a notice for anything other than archiving change
						if (newArchived === oldArchived && jobOrderChanges) {
							useNoticeStore().addJobOrderNotice({
								jobOrder: updatedJobOrder,
								changes: jobOrderChanges
							})
						}
					}
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Job Order (${jobOrder.JobOrderId}) not updated`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error?.response?.data ? error.response.data.message : error.message
				})
			} finally {
				if (options.showLoadingSpinner) {
					craftsMasterStore.loading = false
				}
			}
		},
		async addJobOrderVendor (payload) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await JobOrderDataService.addVendor(payload)

				if (response && response.data) {
					const jobOrder = this.jobOrders.find(j => j.JobOrderId === payload.jobOrderId)
					if (jobOrder) {
						Vue.set(jobOrder, 'Vendors', response.data)

						craftsMasterStore.setSnackbar({
							value: true,
							text: 'Vendor added.'
						})
					}
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: 'Vendor not added.'
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error?.response?.data ? error.response.data.message : error.message,
					type: 'error',
					timeout: -1
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async postJobOrder (payload) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const jobOrderId = payload.jobOrder.JobOrderId
				const response = await JobOrderDataService.postJobOrder(jobOrderId, {
					craftText: JobOrder.Crafts[payload.jobOrder.CraftId].text,
					classText: JobOrder.Classes[payload.jobOrder.ClassId].altText,
					vendorIds: payload.vendorIds
				})
				if (response?.data && response.data.status === 200) {
					const jobOrder = response.data.jobOrder
					const index = this.jobOrders.findIndex(j => j.JobOrderId === jobOrderId)
					if (index !== -1) {
						jobOrder.BillRateLo = jobOrder.BillRate[0]
						jobOrder.BillRateHi = jobOrder.BillRate[1]

						delete jobOrder.BillRate

						Vue.set(this.jobOrders, index, jobOrder)

						craftsMasterStore.setSnackbar({
							value: true,
							text: `Job Order (${jobOrderId}) posted`
						})

						useNoticeStore().addJobOrderNotice({
							jobOrder,
							changes: 'Job Order posted'
						})
					}
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: response?.data?.message ? response.data.message : `Job Order (${jobOrderId}) not posted`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error?.response?.data ? error.response.data.message : error.message,
					timeout: -1
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async deleteJobOrder (id) {
			const craftsMasterStore = useCraftsMasterStore()
			try {
				const response = await JobOrderDataService.delete(id)
				if (response) {
					const index = this.jobOrders.findIndex(j => j.JobOrderId === id)

					if (index !== -1) {
						this.jobOrders.splice(index, 1)
					}

					craftsMasterStore.setSnackbar({
						value: true,
						text: `Job Order (${id}) deleted`
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Job Order (${id}) not updated`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error?.response?.data ? error.response.data.message : error.message
				})
			}
		},
		setCurrentJobOrderId (id = null) {
			this.currentJobOrderId = id
		},
		async submitCraftsmen (payload) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				// only sends an email
				const response = await JobOrderDataService.submitCraftsmen(payload.jobOrderId, payload.vendorId)

				if (response && response.status === 200) {
					await this.updateJobOrderCraftsmen({
						showLoadingSpinner: false,
						statusId: Craftsman.Status.Submitted,
						craftsmanIds: payload.craftsmanIds,
						vendorId: payload.vendorId,
						jobOrderId: payload.jobOrderId
					})

					craftsMasterStore.setSnackbar({
						value: true,
						text: `${payload.craftsmanIds.length} Craftsmen submitted.`
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: 'Craftsmen not submitted.'
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error?.response?.data ? error.response.data.message : error.message,
					type: 'error'
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async updateJobOrderCraftsmen (payload) {
			const { showLoadingSpinner = true, craftsmanIds, statusId, eventId, successMessage } = payload

			const craftsMasterStore = useCraftsMasterStore()
			if (showLoadingSpinner) {
				craftsMasterStore.loading = true
			}

			payload.jobOrderId = payload.jobOrderId || this.currentJobOrderId
			const jobOrder = this.jobOrders.find(j => j.JobOrderId === payload.jobOrderId)

			try {
				if (Craftsman.isUnavailable(statusId)) {
					payload.availableStatusIds = Craftsman.AvailableStatusIds
				}
				const response = await JobOrderDataService.updateCraftsmen(payload)

				if (response && response.data) {
					jobOrder.Craftsmen = response.data

					craftsMasterStore.setSnackbar({
						value: true,
						text: successMessage || `Job Order (${jobOrder.JobOrderId}) updated`
					})

					for (const craftsmanId of craftsmanIds) {
						const changes = getJobOrderCraftsmanChanges({
							jobOrder,
							craftsmanId,
							statusId
						})

						let level = Notice.Level.Low
						if (Craftsman.isOnAssignment(statusId) ||
							Craftsman.isOfferStatus(statusId)) {
							level = Notice.Level.Medium
						}

						useNoticeStore().addJobOrderNotice({
							jobOrder,
							craftsmanId,
							statusId,
							eventId,
							changes,
							level
						})
					}

					// joc has been moved to an unavailable status and has been removed from other job orders,
					// necessitating a refresh of job orders
					// setTimeout needed to cause offer dialog right click event to fire...Vue.nextTick no workie
					setTimeout(() => {
						if (Array.isArray(payload.availableStatusIds)) {
							this.getJobOrdersByUser()
						}
					}, 0)
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Job Order (${jobOrder.JobOrderId}) not updated`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error?.response?.data ? error.response.data.message : error.message
				})
			} finally {
				if (showLoadingSpinner) {
					craftsMasterStore.loading = false
				}
			}
		},
		updateJocToCompletedAssignmentStatus () {
			const userStore = useUserStore()
			let jobOrders = []
			switch (userStore.currentCompany.typeId) {
				case User.UserType.Vendor:
					jobOrders = this.vendorJobOrders
					break
				case User.UserType.Employer:
					jobOrders = this.employerJobOrders
					break
			}
			return jobOrders
		},
		// async getTestJobOrder () {
		// 	const response = await JobOrderDataService.getTestJobOrder()

		// 	if (response) {
		// 		this.testJobOrder = { ...response.data }
		// 	}
		// },
		setJobOrderFilter (filter) {
			this.jobOrderFilter = JSON.parse(JSON.stringify(filter))
		},
		getFilteredJobOrders (filter) {
			const userStore = useUserStore()

			this.filteredJobOrders = filterList({
				filter: filter || this.jobOrderFilter,
				list: userStore.currentUser.TypeId === User.UserType.Employer ? this.employerJobOrders : this.jobOrders,
				fields: [...JobOrder.Fields],
				fieldNames: JobOrder.FieldNames
			})
		},
		setShowInactiveJobOrders () {
			this.showInactiveJobOrders = !this.showInactiveJobOrders
		},
		setIncludeArchivedJobOrders () {
			this.includeArchivedJobOrders = !this.includeArchivedJobOrders
		},
		resetInactiveAndArchivedJobOrderFlags () {
			this.showInactiveJobOrders = false
			this.includeArchivedJobOrders = false
		},
		async updateJobOrderIds (router) {
			const employerStore = useEmployerStore()
			const ids = this.jobOrders.map(j => {
				const employer = employerStore.employers.find(e => e.EmployerId === j.EmployerId)
				const hash = chance.hash({ length: hashLength, casing: 'upper' })
				return {
					old: j.JobOrderId,
					new: `${JobOrder.Crafts[j.CraftId].code}${JobOrder.Classes[j.ClassId].code}-${employer.EmployerCode}-${hash}`
				}
			})
			if (ids) {
				const response = await JobOrderDataService.updateJobOrderIds(ids)

				if (response?.status === 200) {
					router.go(0)
				}
			}
		},
		setJobOrderView (view) {
			this.jobOrderView = view
		}
	}
})
