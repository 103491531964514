import { http } from '@/common/utils'
// import { useCraftsMasterStore } from '@/store/craftsMasterStore'
// import { useVendorStore } from '@/store/vendorStore'
// import Chance from 'chance'
// import CraftsMaster from '@/common/craftsMasterResolver'

// const chance = Chance()
// const TestDataLimit = CraftsMaster.TestDataLimit // readability
// const CraftsmenKey = 'craftsmaster:craftsmen'

// const getTestCraftsman = (vendorId) => {
// 	const name = chance.name().split(' ')
// 	const craftsman = {
// 		Name: `${name[0]} ${name[1]}`,
// 		CraftId: chance.integer({ min: 0, max: 18 }),
// 		ClassId: chance.integer({ min: 0, max: 2 }),
// 		Rate: chance.floating({ fixed: 2, min: 15, max: 50 }),
// 		VendorId: vendorId,
// 		Address: chance.address(),
// 		City: chance.city(),
// 		State: chance.state(),
// 		Zip: chance.zip(),
// 		Phone: chance.phone(),
// 		Email: `${name[0]}.${name[1]}@${chance.word()}.com`,
// 		CraftsmanId: chance.hash({ length: 15 }),
// 		Resume: '',
// 		ResumeFormData: null,
// 		expanded: false // for craftsman table to display extra data (address, etc.)
// 	}
// 	return craftsman
// }

// const getTestCraftsmenByVendorId = (vendorId) => {
// 	return new Promise((resolve, reject) => {
// 		const date = new Date()
// 		let craftsmen = localStorage.getItem(CraftsmenKey)
// 		if (craftsmen) {
// 			craftsmen = JSON.parse(craftsmen)
// 		} else {
// 			craftsmen = []
// 			const craftsMasterStore = useCraftsMasterStore()
// 			const craftsmanCount = chance.integer({
// 				min: craftsMasterStore.testDataLimits[TestDataLimit.Craftsmen].range[0],
// 				max: craftsMasterStore.testDataLimits[TestDataLimit.Craftsmen].range[1]
// 			})

// 			for (let i = 0; i < craftsmanCount; i++) {
// 				const craftsman = getTestCraftsman(vendorId)
// 				craftsmen.push(craftsman)
// 			}
// 		}
// 		console.log(`craftsmen: ${craftsmen.length} in ${new Date() - date} ms`)
// 		resolve({ data: craftsmen })
// 		reject(new Error('CraftsmanDataService.getTestCraftsmen: Error'))
// 	})
// }

// const getTestCraftsmenByEmployerId = (employerId) => {
// 	return new Promise((resolve, reject) => {
// 		const date = new Date()
// 		let craftsmen = localStorage.getItem(CraftsmenKey)
// 		if (craftsmen) {
// 			craftsmen = JSON.parse(craftsmen)
// 		} else {
// 			const craftsMasterStore = useCraftsMasterStore()
// 			const vendorStore = useVendorStore()
// 			const vendorIds = vendorStore.vendors
// 				.filter(v => v.EmployerId === employerId)
// 				.map(v => v.VendorId)

// 			craftsmen = []
// 			vendorIds.forEach(vendorId => {
// 				const craftsmanCount = chance.integer({
// 					min: craftsMasterStore.testDataLimits[TestDataLimit.Craftsmen].range[0],
// 					max: craftsMasterStore.testDataLimits[TestDataLimit.Craftsmen].range[1]
// 				})

// 				for (let i = 0; i < craftsmanCount; i++) {
// 					const craftsman = getTestCraftsman(vendorId)
// 					craftsmen.push(craftsman)
// 				}
// 			})
// 			localStorage.setItem(CraftsmenKey, JSON.stringify(craftsmen))
// 		}
// 		console.log(`craftsmen: ${craftsmen.length} in ${new Date() - date} ms`)
// 		resolve({ data: craftsmen })
// 		reject(new Error('CraftsmanDataService.getTestCraftsmen: Error'))
// 	})
// }

class CraftsmanDataService {
	getAll () {
		return http.get('/craftsmen')
	}

	getByVendorId (vendorId) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return getTestCraftsmenByVendorId(vendorId)
		// } else {
		return http.get(`/craftsmen/vendor/${vendorId}`)
		// }
	}

	getByJobOrderId (jobOrderId) {
		return http.get(`/craftsmen/jobOrder/${jobOrderId}`)
	}

	getByEmployerId (employerId) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return getTestCraftsmenByEmployerId(employerId)
		// } else {
		return http.get(`/craftsmen/employer/${employerId}`)
		// }
	}

	// async getTestCraftsman (vendorId) {
	// 	const testCraftsman = getTestCraftsman(vendorId)
	// 	return { data: testCraftsman }
	// }

	// async getTestCraftsmenByVendorId (vendorId) {
	// 	const testCraftsmen = await getTestCraftsmenByVendorId(vendorId)
	// 	return http.post('/craftsmen/bulk', testCraftsmen)
	// }

	create (data) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		const craftsman = Object.assign({}, data)
		// 		craftsman.CraftsmanId = chance.hash({ length: 15 })
		// 		craftsman.expanded = false
		// 		let craftsmen = localStorage.getItem(CraftsmenKey)
		// 		if (craftsmen) {
		// 			craftsmen = JSON.parse(craftsmen)
		// 			craftsmen.push(craftsman)
		// 			localStorage.setItem(CraftsmenKey, JSON.stringify(craftsmen))
		// 			resolve({ data: craftsman })
		// 		} else {
		// 			reject(new Error('CraftsmanDataService.create: no craftsmen found in localStorage'))
		// 		}
		// 	})
		// } else {
		return http.post('/craftsmen', data)
		// }
	}

	update (id, data) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		let craftsmen = localStorage.getItem(CraftsmenKey)
		// 		if (craftsmen) {
		// 			craftsmen = JSON.parse(craftsmen)
		// 			const craftsman = craftsmen.find(c => c.CraftsmanId === id)
		// 			if (craftsman) {
		// 				Object.assign(craftsman, data)
		// 				localStorage.setItem(CraftsmenKey, JSON.stringify(craftsmen))
		// 				resolve({ data: craftsman })
		// 			} else {
		// 				reject(new Error(`CraftsmanDataService.update: craftsman ${id} not found in localStorage`))
		// 			}
		// 		} else {
		// 			reject(new Error('CraftsmanDataService.update: no craftsmen found in localStorage'))
		// 		}
		// 	})
		// } else {
		return http.put(`/craftsmen/${id}`, data)
		// }
	}

	upload (id, formData) {
		return http.post(`/craftsmen/resume/${id}`, formData, {
			headers: {
				'Content-type': 'multipart/form-data'
			}
		})
	}

	delete (id) {
		return http.delete(`/craftsmen/${id}`)
	}

	deleteMany (ids) {
		return http.post('/craftsmen/delete', ids)
	}

	unarchive (ids) {
		return http.post('/craftsmen/unarchive/bulk', ids)
	}

	archive (ids) {
		return http.post('/craftsmen/archive/bulk', ids)
	}

	updateCraftsmanIds (ids) {
		return http.patch('/craftsmen/updateCraftsmanIds', ids)
	}
}

export default new CraftsmanDataService()
