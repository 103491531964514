export const Type = {
	Interview: 0,
	Testing: 1, // technical interview
	Onboarding: 2,
	Assignment: 3,
	Other: 4,
	JobOrder: 5
}

export const Types = [
	{
		value: Type.Interview,
		text: 'Interview'
	}, {
		value: Type.Testing,
		text: 'Testing'
	}, {
		value: Type.Onboarding,
		text: 'Onboarding'
	}, {
		value: Type.Assignment,
		text: 'Assignment'
	}, {
		value: Type.Other,
		text: 'Other'
	}, {
		value: Type.JobOrder,
		text: 'Job Order'
	}
]
export const Status = {
	Pending: 0,
	Scheduled: 1,
	Unscheduled: 2
}

export const Statuses = [
	{
		value: Status.Pending,
		text: 'pending',
		color: 'indigo'
	}, {
		value: Status.Scheduled,
		text: 'scheduled',
		color: 'green'
	}, {
		value: Status.Unscheduled,
		text: 'unscheduled',
		color: 'grey'
	}
]

const Field = {
	TypeId: 0, // interview, job order, craftsman job order start date, craftsman onboarding start date
	Start: 1,
	End: 2,
	CraftsmanId: 3,
	StatusId: 4, // 0 == pending, 1 == scheduled, 2 == unscheduled
	JobOrderId: 5,
	Location: 6,
	Notes: 7,
	CreatorId: 8,
	EventId: 9
}

const Fields = [{
	id: Field.TypeId,
	component: 'v-select',
	label: 'Type',
	value: null,
	required: true,
	groupable: true,
	sortable: true,
	width: '9%',
	items: Types
}, {
	id: Field.Start,
	component: 'v-date-picker',
	label: 'Start Date',
	value: null,
	menuValue: false,
	width: '8%',
	cols: 6,
	isDate: true,
	required: true,
	sortable: true
}, {
	id: Field.End,
	component: 'v-date-picker',
	label: 'End Date',
	value: null,
	menuValue: false,
	width: '8%',
	cols: 6,
	isDate: true,
	required: true,
	sortable: true
}, {
	id: Field.CraftsmanId,
	component: 'v-select',
	label: 'Craftsman',
	value: null,
	required: true,
	groupable: true,
	sortable: true,
	items: null
}, {
	id: Field.StatusId,
	component: 'v-select',
	label: 'Status',
	value: null,
	required: true,
	groupable: true,
	sortable: true,
	items: Statuses
}, {
	id: Field.JobOrderId,
	component: 'v-select',
	label: 'Job Order',
	value: null,
	required: true,
	groupable: true,
	sortable: true,
	items: null
}, {
	id: Field.Location,
	component: 'v-text-field',
	label: 'Location',
	value: null
}, {
	id: Field.Notes,
	component: 'v-textarea',
	label: 'Notes',
	expanded: true,
	value: null
}, {
	id: Field.CreatorId,
	component: 'v-select',
	label: 'Creator',
	value: null,
	required: true,
	sortable: true,
	groupable: true,
	items: null
}, {
	id: Field.EventId
}]

const FieldNames = Object.keys(Field)

export default {
	Type,
	Types,
	Status,
	Statuses,
	Field,
	Fields,
	FieldNames
}
