import { http } from '@/common/utils'
// import Chance from 'chance'
// import CraftsMaster from '@/common/craftsMasterResolver'
// import { UsersKey } from '@/common/userResolver'

// const chance = Chance()

// const getTestUser = (payload) => {
// 	let username = payload.username

// 	return new Promise((resolve, reject) => {
// 		username = username.toLowerCase()
// 		const companyName = chance.company()
// 		const name = chance.name().split(' ')

// 		let emailDomain = companyName.replace(/ /g, '').split(',')[0]
// 		emailDomain = emailDomain.split('').reverse()
// 		if (emailDomain[0] === '.') {
// 			emailDomain.shift()
// 		}
// 		emailDomain = emailDomain.reverse().join('')

// 		if (payload.adminOnly && username !== 'charles') {
// 			reject(new Error('UserDataService.getTestUser: User must an admin'))
// 		} else if (username === 'charles' || username === 'luke' || username === 'loring') {
// 			let user
// 			if (username === 'charles') {
// 				user = {
// 					UserId: '0000000000',
// 					Username: username,
// 					Passcode: '',
// 					Name: 'Admin',
// 					Phone: '513.490.3720',
// 					Email: 'charles.joseph.simpson@gmail.com',
// 					IsAdmin: true,
// 					Notes: 'admin account'
// 				}
// 			} else {
// 				const userKey = `${UsersKey}/${username}`
// 				user = localStorage.getItem(userKey)
// 				if (user) {
// 					user = JSON.parse(user)
// 				} else {
// 					user = {
// 						UserId: chance.hash({ length: 10 }),
// 						Username: username,
// 						Passcode: chance.hash({ length: 10 }),
// 						Name: name.join(' '),
// 						Phone: chance.phone(),
// 						Email: `${name[0]}.${name[1]}@${emailDomain}.com`,
// 						IsAdmin: false,
// 						IsPrimary: true,
// 						Notes: ''
// 					}
// 					localStorage.setItem(userKey, JSON.stringify(user))
// 				}
// 			}
// 			resolve({ data: user })
// 		} else {
// 			resolve({
// 				UserId: chance.hash({ length: 10 }),
// 				Username: username,
// 				Passcode: chance.hash({ length: 10 }),
// 				Name: name.join(' '),
// 				Phone: chance.phone(),
// 				Email: `${name[0]}.${name[1]}@${emailDomain}.com`,
// 				IsAdmin: false,
// 				IsPrimary: true,
// 				Notes: ''
// 			})
// 		}
// 		reject(new Error('UserDataService.getTestUser: Unknown user'))
// 	})
// }

// const getTestUsers = (count) => {
// 	return new Promise((resolve, reject) => {
// 		const date = new Date()

// 		const users = []

// 		const userCount = count || chance.integer({
// 			min: 1,
// 			max: 5
// 		})
// 		for (let i = 0; i < userCount; i++) {
// 			getTestUser({
// 				username: chance.word()
// 			}).then(data => {
// 				users.push(data)
// 			})
// 		}
// 		localStorage.setItem(UsersKey, JSON.stringify(users))

// 		console.log(`users: ${users.length} in ${new Date() - date} ms`)
// 		resolve({ data: users })
// 		reject(new Error('UserDataService.getTestUsers: error getting users'))
// 	})
// }

export default {
	getAll () {
		return http.get('/users')
	},
	getPrimaryByCompanyId (id) {
		return http.get(`/users/companyId/${id}`)
	},
	create (user) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		const key = `${UsersKey}/${user.username.toLowerCase()}`
		// 		const existingUser = localStorage.getItem(key)
		// 		if (!existingUser) {
		// 			localStorage.setItem(key, JSON.stringify(user))
		// 		}
		// 		resolve({ data: user })
		// 		reject(new Error('UserDataService.post: Error posting user'))
		// 	})
		// } else {
		return http.post('/users', user)
		// }
	},
	update (user) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		const key = `${UsersKey}/${user.username.toLowerCase()}`
		// 		const existingUser = localStorage.getItem(key)
		// 		if (existingUser) {
		// 			localStorage.setItem(key, JSON.stringify(user))
		// 			resolve({ data: user })
		// 		} else {
		// 			reject(new Error(`UserDataService.put: User "${user.Username}" not found`))
		// 		}
		// 	})
		// } else {
		return http.put(`/users/${user.UserId}`, user)
		// }
	},
	delete (id) {
		return http.delete(`/users/${id}`)
	},
	deleteAll () {
		return http.delete('/users')
	},
	setLoginDate (id) {
		return http.patch(`/users/setLoginDate/${id}`)
	}
	// async getTestUsers () {
	// 	const testUsers = await getTestUsers()
	// 	return http.post('/users/bulk', testUsers.data)
	// }

	// async getTestUser () {
	// 	const testUser = await getTestUsers(1)
	// 	return { data: testUser.data[0] }
	// }
}
