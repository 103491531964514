import Vue from 'vue'
import { VTextField, VMenu, VList, VSelect, VDivider } from 'vuetify/lib'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify, {
	components: {
		VTextField,
		VMenu,
		VList,
		VSelect,
		VDivider
	}
})

export default new Vuetify({
})
