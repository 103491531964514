import { defineStore } from 'pinia'
import EventDataService from '@/services/EventDataService'
import { useCraftsMasterStore } from './craftsMasterStore'
import { useJobOrderStore } from './jobOrderStore'
import { useVendorStore } from './vendorStore'
import { useCraftsmanStore } from './craftsmanStore'
import { useUserStore } from './userStore'
import { useNoticeStore } from './noticeStore'
import { Portals, Portal, View } from '@/common/craftsMasterResolver'
import Chance from 'chance'
import Vue from 'vue'
import Event from '@/common/eventResolver'
import JobOrder from '@/common/jobOrderResolver'
import moment from 'moment'

const chance = Chance()

function addEventNotice ({ event, actionText }) {
	let Notes = `<div>Event ${actionText}: ${Event.Types[event.TypeId].text} (${Event.Statuses[event.StatusId].text})</div>`
	Notes += `<div>Start: <span class="font-weight-bold">${moment(event.Start).format('ddd MMM D, YYYY h:mm a')}</span></div>`
	Notes += `<div>End: <span class="font-weight-bold">${moment(event.End).format('ddd MMM D, YYYY h:mm a')}</span></div>`

	useNoticeStore().addNotice({
		EventId: event.EventId,
		JobOrderId: event.JobOrderId,
		CraftsmanId: event.CraftsmanId,
		Notes
	})
}

export const useEventStore = defineStore('event', {
	state: () => ({
		events: [],
		calendar: {
			focus: '',
			type: 'month'
		},
		currentEvent: null
	}),
	getters: {
		eventsTableView (state) {
			const craftsmanStore = useCraftsmanStore()
			const jobOrderStore = useJobOrderStore()
			const vendorStore = useVendorStore()
			const events = state.events
				.map(e => {
					const craftsman = craftsmanStore.craftsmen.find(c => c.CraftsmanId === e.CraftsmanId)
					const jobOrder = jobOrderStore.jobOrders.find(j => j.JobOrderId === e.JobOrderId)
					// ensure craftsman is on job order
					if (jobOrder?.Craftsmen.findIndex(joc => joc.CraftsmanId === craftsman?.CraftsmanId) !== -1) {
						const vendor = vendorStore.vendors.find(v => v.VendorId === craftsman.VendorId)
						if (vendor) {
							return {
								...e,
								Type: Event.Types[e.TypeId].text,
								craftsmanName: craftsman.Name,
								vendorName: vendor.Name,
								startDate: moment(e.Start).format('MMM D, YYYY'),
								startTime: moment(e.Start).format('h:mm a'),
								endDate: moment(e.End).format('MMM D, YYYY'),
								endTime: moment(e.End).format('h:mm a'),
								jobOrderName: JobOrder.getJobTitle(jobOrder).craftClassJobOrderId,
								jobOrderRoute: {
									name: Portals[Portal.Employer].tabs[View.EmployerJobOrderCraftsmen].name,
									params: {
										companyId: jobOrder.EmployerId,
										jobOrderId: e.JobOrderId
									}
								}
							}
						} else {
							return null
						}
					} else {
						return null
					}
				})
			return events
		}
	},
	actions: {
		setCalendarFocus (date) {
			this.calendar.focus = date
		},
		setCalendarType (type) {
			this.calendar.type = type
		},
		resetCalendar () {
			this.calendar = {
				focus: '',
				type: 'month'
			}
		},
		clearEvents () {
			this.events = []
		},
		async getEvents () {
			try {
				const response = await EventDataService.getAll()
				if (response) {
					this.events = response.data
				}
			} catch (error) {
				this.events = []

				const craftsMasterStore = useCraftsMasterStore()
				craftsMasterStore.setSnackbar({
					value: true,
					text: error.response.data.message,
					type: 'error'
				})
			}
		},
		async getEventById (id) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await EventDataService.getById(id)
				if (response && response.data) {
					const index = this.events.findIndex(i => i.EventId === response.data.EventId)
					if (index === -1) {
						this.events.push(response.data)
					}
				}
			} catch (error) {
				const craftsMasterStore = useCraftsMasterStore()
				craftsMasterStore.setSnackbar({
					value: true,
					text: error.response.data.message,
					type: 'error'
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async saveEvent (event) {
			if (event.hasOwnProperty('EventId') && event.EventId) {
				await this.updateEvent(event)
			} else {
				await this.addEvent(event)
			}
		},
		async addEvent (event) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				event.EventId = chance.hash({ length: 10 })
				const response = await EventDataService.create(event)
				if (response && response.data) {
					this.events.push(response.data)
					this.currentEvent = response.data

					addEventNotice({
						event: this.currentEvent,
						actionText: 'added'
					})

					craftsMasterStore.setSnackbar({
						value: true,
						text: `${Event.Types[response.data.TypeId].text} on (${moment(response.data.Start).format('ddd MMM D, YYYY h:mm a')}) added`
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `${Event.Types[event.TypeId].text} on (${moment(event.Start).format('ddd MMM D, YYYY h:mm a')}) not added`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response.data.message
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async updateEvent (event) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await EventDataService.update(event.EventId, event)
				if (response && response.data) {
					const index = this.events.findIndex(i => i.EventId === response.data.EventId)
					if (index !== -1) {
						Vue.set(this.events, index, response.data)
						this.currentEvent = response.data

						addEventNotice({
							event: this.currentEvent,
							actionText: 'updated'
						})

						craftsMasterStore.setSnackbar({
							value: true,
							text: `${Event.Types[response.data.TypeId].text} on (${moment(response.data.Start).format('ddd MMM D, YYYY h:mm a')}) updated`
						})
					}
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `${Event.Types[event.TypeId].text} on (${moment(event.Start).format('ddd MMM D, YYYY h:mm a')}) not updated`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response.data.message
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async deleteEventById (payload) {
			const craftsMasterStore = useCraftsMasterStore()
			const start = new Date(payload.start).toDateString()

			if (payload.eventId) {
				craftsMasterStore.loading = true

				try {
					const response = await EventDataService.delete(payload.eventId)
					if (response) {
						const deletedIndex = this.events.findIndex(i => i.EventId === response.data)
						if (deletedIndex !== -1) {
							const deletedEvent = Object.assign({}, this.events[deletedIndex])

							this.events.splice(deletedIndex, 1)

							addEventNotice({
								event: deletedEvent,
								actionText: 'removed'
							})
						}

						craftsMasterStore.setSnackbar({
							value: true,
							text: `Event on (${start}) deleted`
						})
					} else {
						craftsMasterStore.setSnackbar({
							value: true,
							type: 'error',
							text: `Event on (${start}) not deleted`
						})
					}
				} catch (error) {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: error.response.data.message
					})
				} finally {
					craftsMasterStore.loading = false
				}
			} else {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: `Event on (${start}) not deleted: no EventId`
				})
			}
		},
		async deleteAllEvents () {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				await EventDataService.deleteAll()
				this.clearEvents()
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response.data.message
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async getEventsByCompanyId () {
			const userStore = useUserStore()

			if (userStore.currentCompanyId || userStore.isAdmin) {
				const craftsMasterStore = useCraftsMasterStore()
				craftsMasterStore.loading = true

				try {
					let response
					if (userStore.isEmployer) {
						response = await EventDataService.getByEmployerId(userStore.currentCompanyId)
					} else if (userStore.isVendor) {
						response = await EventDataService.getByVendorId(userStore.currentCompanyId)
					} else if (userStore.isAdmin) {
						response = await EventDataService.getAll()
					}
					if (response && response.data) {
						this.events = response.data
					} else {
						this.events = []
					}
				} catch (error) {
					this.events = []

					const craftsMasterStore = useCraftsMasterStore()
					craftsMasterStore.setSnackbar({
						value: true,
						text: error.response.data.message,
						type: 'error'
					})
				} finally {
					craftsMasterStore.loading = false
				}
			}
		},
		async deletePendingEvents (payload) {
			const craftsMasterStore = useCraftsMasterStore()

			craftsMasterStore.loading = true

			try {
				await EventDataService.deletePending(payload)

				const eventIds = this.events.filter(e => {
					return e.StatusId === Event.Status.Pending &&
						e.TypeId === payload.typeId &&
						e.JobOrderId === payload.jobOrderId &&
						e.CraftsmanId === payload.craftsmanId
				}).map(e => e.EventId)

				eventIds.forEach(id => {
					const index = this.events.findIndex(e => e.EventId === id)
					if (index !== -1) {
						this.events.splice(index, 1)
					}
				})
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.message
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async deleteJobOrderCraftsmanEvents (payload) {
			const craftsMasterStore = useCraftsMasterStore()

			try {
				const response = await EventDataService.deleteJobOrderCraftsman(payload)

				if (response?.status === 200) {
					this.getEventsByCompanyId()
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Events not deleted: Job Order Id (${payload.jobOrderId}), Craftsman Id (${payload.craftsmanId})`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.message
				})
			}
		},
		/****************************************************/
		/**    below actions currently not being called    **/
		/****************************************************/
		async getEventsByJobOrderId (jobOrderId) {
			const jobOrderStore = useJobOrderStore()
			jobOrderId = jobOrderId || jobOrderStore.currentJobOrderId

			if (jobOrderId) {
				const craftsMasterStore = useCraftsMasterStore()
				craftsMasterStore.loading = true

				try {
					const response = await EventDataService.getByJobOrderId(jobOrderId)
					if (response && response.data) {
						this.events = response.data
					} else {
						this.events = []
					}
				} catch (error) {
					this.events = []

					const craftsMasterStore = useCraftsMasterStore()
					craftsMasterStore.setSnackbar({
						value: true,
						text: error.response.data.message,
						type: 'error'
					})
				} finally {
					craftsMasterStore.loading = false
				}
			}
		},
		getEventsByCraftsmanId (craftsmanId) {
			const craftsmanStore = useCraftsmanStore()
			const craftsman = craftsmanStore.craftsmen.find(c => c.CraftsmanId === craftsmanId)
			let events = []
			if (craftsman) {
				events = this.events.filter(e => e.CraftsmanId === craftsmanId)
			}
			return events
		}
	}
})
