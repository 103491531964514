import { defineStore } from 'pinia'
import CraftsMaster from '@/common/craftsMasterResolver'
import { useNoticeStore } from '@/store/noticeStore'

export const useCraftsMasterStore = defineStore('craftsMaster', {
	state: () => ({
		drawerComponent: null,
		loading: false,
		snackbar: {
			value: false,
			text: '',
			color: 'green darken-2',
			timeout: 2500
		},
		testDataLimits: [],
		tabs: [],
		leftMenuBackText: '',
		fileUploadInProgress: false,
		currentAnchor: null,
		currentTab: null,
		route: null
	}),
	actions: {
		async setTestDataLimits (testDataLimits) {
			if (!testDataLimits) {
				testDataLimits = localStorage.getItem(CraftsMaster.TestDataLimitsKey)
				if (testDataLimits) {
					testDataLimits = JSON.parse(testDataLimits)
				} else {
					testDataLimits = JSON.parse(JSON.stringify(CraftsMaster.DefaultTestDataLimits))
					localStorage.setItem(CraftsMaster.TestDataLimitsKey, JSON.stringify(testDataLimits))
				}
			} else {
				localStorage.setItem(CraftsMaster.TestDataLimitsKey, JSON.stringify(testDataLimits))
			}
			this.testDataLimits = JSON.parse(JSON.stringify(testDataLimits))
		},
		setDrawerComponent (component) {
			this.drawerComponent = component ? Object.assign({}, component) : null
		},
		setLoading (loading) {
			this.loading = loading
		},
		setSnackbar (snackbar) {
			if (snackbar) {
				this.snackbar.value = snackbar.value || false
				this.snackbar.type = snackbar.type || ''
				this.snackbar.text = snackbar.text || ''
				this.snackbar.maxWidth = snackbar.maxWidth || ''
				this.snackbar.multiline = snackbar.multiline || false
				this.snackbar.vertical = snackbar.vertical || false
				this.snackbar.color = snackbar.color || (snackbar.type === 'error' ? 'red darken-2' : 'green darken-2')
				this.snackbar.timeout = snackbar.timeout || (snackbar.type === 'error' ? -1 : 2500)

				if (this.snackbar.type === 'error') {
					let Notes = '<div class="font-weight-bold red--text">Error</div>'
					Notes += `<div>${this.snackbar.text}</div>`
					useNoticeStore().addNotice({
						Notes,
						Level: 2
					})
				}
			}
		},
		showSnackbar (show) {
			this.snackbar.value = show
		},
		setTabs (tabs, tab) {
			this.tabs = tabs
			if (tab) {
				this.currentTab = tab
			}
		},
		setLeftMenuBackText (text) {
			this.leftMenuBackText = text
		},
		setFileUploadInProgress (val) {
			this.fileUploadInProgress = val
		},
		setCurrentAnchor (val) {
			this.currentAnchor = val
		},
		setRoute (val) {
			this.route = val
		}
	}
})
