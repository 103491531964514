import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import App from './App.vue'
import SharedComponents from './components/shared'
import EmployerComponents from './components/Employer'
import VendorComponents from './components/Vendor'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import { PiniaVuePlugin } from 'pinia'
import router, { pinia } from './router'
import ResSplitPane from 'vue-resize-split-pane'
import VueScrollTo from 'vue-scrollto'
import VueHotKey from 'v-hotkey'
import 'animate.css'
import currency from 'v-currency-field'
import 'v-currency-field/dist/index.css'

Vue.use(currency)

Vue.use(VueHotKey)

Vue.use(PiniaVuePlugin)

Vue.component('rs-panes', ResSplitPane)
Vue.use(VueScrollTo)

SharedComponents()
EmployerComponents()
VendorComponents()

Vue.config.productionTip = false

new Vue({
	router,
	pinia,
	vuetify,
	render: h => h(App)
}).$mount('#app')
