import { http } from '@/common/utils'

export default {
	getAll () {
		return http.get('/notices')
	},
	create (data) {
		return http.post('/notices', data)
	}
}
