import User from './userResolver'

export const TypeCode = {
	Employer: 0,
	Vendor: 1,
	Craftsman: 2,
	JobOrder: 3
}

export const TypeCodes = [{
	id: TypeCode.Employer,
	code: 'EM'
}, {
	id: TypeCode.Vendor,
	code: 'VE'
}, {
	id: TypeCode.Craftsman,
	code: 'CM'
}, {
	id: TypeCode.JobOrder,
	code: 'JO'
}]

export const Portal = {
	Employer: 0,
	Vendor: 1,
	Admin: 2
}

export const View = {
	EmployerDashboard: 0,
	EmployerJobOrders: 1,
	EmployerCraftsmen: 2,
	EmployerJobOrderCraftsmen: 3,
	EmployerYards: 4,
	EmployerNotices: 5,
	VendorJobOrders: 0,
	VendorCraftsmen: 1,
	VendorJobOrderCraftsmen: 2,
	VendorCraftsmanJobOrderHistory: 3,
	VendorNotices: 4,
	AdminEmployers: 0,
	AdminVendors: 1,
	AdminCraftsmen: 2,
	AdminUsers: 3,
	AdminJobOrders: 4,
	AdminEvents: 5,
	AdminYards: 6,
	AdminNotices: 7
}

export const StateList = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MH', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY']

export const Portals = [
	{
		id: Portal.Employer,
		title: 'Employer',
		path: '/employer/:companyId',
		name: 'employer',
		tabs: [{
			id: View.EmployerDashboard,
			text: 'Overview',
			path: 'dashboard',
			name: 'employerDashboard'
		}, {
			id: View.EmployerJobOrders,
			text: 'Job Orders',
			path: 'joborders',
			name: 'employerJobOrders'
		}, {
			id: View.EmployerCraftsmen,
			text: 'Craftsmen',
			path: 'craftsmen',
			name: 'employerCraftsmen'
		}, {
			id: View.EmployerJobOrderCraftsmen,
			text: 'Job Order',
			path: 'joborder/:jobOrderId',
			name: 'employerJobOrderCraftsmen',
			solo: true
		}, {
			id: View.EmployerYards,
			text: 'Yards',
			path: 'yards',
			name: 'employerYards'
		}, {
			id: View.EmployerNotices,
			text: 'Notices',
			path: 'notices',
			name: 'employerNotices'
		}]
	}, {
		id: Portal.Vendor,
		title: 'Vendor',
		path: '/vendor/:companyId',
		name: 'vendor',
		tabs: [{
			id: View.VendorJobOrders,
			text: 'Job Orders',
			path: 'joborders',
			name: 'vendorJobOrders'
		}, {
			id: View.VendorCraftsmen,
			text: 'Craftsmen',
			path: 'craftsmen',
			name: 'vendorCraftsmen'
		}, {
			id: View.VendorJobOrderCraftsmen,
			text: 'Job Order',
			path: 'joborder/:jobOrderId',
			name: 'vendorJobOrderCraftsmen',
			solo: true
		}, {
			id: View.VendorCraftsmanJobOrderHistory,
			text: 'History',
			path: 'craftsman/:craftsmanId',
			name: 'vendorCraftsmanJobOrderHistory',
			solo: true
		}, {
			id: View.VendorNotices,
			text: 'Notices',
			path: 'notices',
			name: 'vendorNotices'
		}]
	}, {
		id: Portal.Admin,
		title: 'Admin',
		path: '/admin',
		name: 'admin',
		tabs: [{
			id: View.AdminEmployers,
			text: 'Employers',
			path: 'employers',
			name: 'adminEmployers'
		}, {
			id: View.AdminVendors,
			text: 'Vendors',
			path: 'vendors',
			name: 'adminVendors'
		}, {
			id: View.AdminCraftsmen,
			text: 'Craftsmen',
			path: 'craftsmen',
			name: 'adminCraftsmen'
		}, {
			id: View.AdminUsers,
			text: 'Users',
			path: 'users',
			name: 'adminUsers'
		}, {
			id: View.AdminJobOrders,
			text: 'Job Orders',
			path: 'joborders',
			name: 'adminJobOrders'
		}, {
			id: View.AdminEvents,
			text: 'Events',
			path: 'events',
			name: 'adminEvents'
		}, {
			id: View.AdminYards,
			text: 'Yards',
			path: 'yards',
			name: 'adminYards'
		}, {
			id: View.AdminNotices,
			text: 'Notices',
			path: 'notices',
			name: 'adminNotices'
		}]
	}
]

const getPortalByUserType = (userType) => {
	let portal
	switch (userType) {
		case User.UserType.Employer:
			portal = Portals[Portal.Employer]
			break
		case User.UserType.Vendor:
			portal = Portals[Portal.Vendor]
			break
		case User.UserType.Admin:
			portal = Portals[Portal.Admin]
			break
	}
	return portal
}

const getTabByRouteName = (name) => {
	let tab

	for (const p of Portals) {
		const t = p.tabs.find(t => t.name === name)
		if (t) {
			tab = t
			break
		}
	}

	return tab
}

export const TestDataLimit = {
	Employers: 0,
	Vendors: 1,
	Craftsmen: 2,
	JobOrders: 3,
	CraftsmanCountPerJobOrder: 4,
	VendorsPerJobOrder: 5,
	InProgressJobOrders: 6
}

const DefaultTestDataLimit = {
	Employers: {
		id: TestDataLimit.Employers,
		text: 'Employers',
		min: 1,
		max: 10,
		range: [1, 5]
	},
	Vendors: {
		id: TestDataLimit.Vendors,
		text: 'Vendors',
		min: 1,
		max: 100,
		range: [15, 30]
	},
	Craftsmen: {
		id: TestDataLimit.Craftsmen,
		text: 'Craftsmen',
		min: 0,
		max: 1000,
		range: [0, 500]
	},
	JobOrders: {
		id: TestDataLimit.JobOrders,
		text: 'Job Orders',
		min: 0,
		max: 500,
		range: [50, 100]
	},
	CraftsmanCountPerJobOrder: {
		id: TestDataLimit.CraftsmanCountPerJobOrder,
		text: 'Craftsman Count per Job Order',
		min: 0,
		max: 100,
		range: [1, 50]
	},
	VendorsPerJobOrder: {
		id: TestDataLimit.VendorsPerJobOrder,
		text: 'Vendors Per Job Order',
		min: 0,
		max: 50,
		range: [0, 5]
	},
	InProgressJobOrders: {
		id: TestDataLimit.InProgressJobOrders,
		text: 'In Progress Job Orders',
		min: 0,
		max: 200,
		range: [100, 100]
	}
}

const DefaultTestDataLimits = [
	DefaultTestDataLimit.Employers,
	DefaultTestDataLimit.Vendors,
	DefaultTestDataLimit.Craftsmen,
	DefaultTestDataLimit.JobOrders,
	DefaultTestDataLimit.CraftsmanCountPerJobOrder,
	DefaultTestDataLimit.VendorsPerJobOrder,
	DefaultTestDataLimit.InProgressJobOrders
]

export const DataSource = 'remote' // test, local, remote
export const Version = '2024.12.03'

export const LoginKey = 'craftsmaster:login'

export default {
	DataSource,
	Version,
	Portal,
	Portals,
	View,
	getPortalByUserType,
	getTabByRouteName,
	StateList,
	LoginKey,
	TestDataLimitsKey: 'craftsmaster:testdatalimits',
	TestDataLimit,
	DefaultTestDataLimits,
	TypeCode,
	TypeCodes
}
