<template>
	<v-app>
		<v-overlay opacity="0.9" :value="loading" z-index="200">
			<v-img
				src="cm-logo-cooper-black.png"
				class="animate__animated animate__rotateIn animate__infinite"
				eager contain
				width="125" height="125" />
		</v-overlay>
		<v-snackbar
			right top
			:color="snackbar.color"
			:timeout="snackbar.timeout"
			outlined text
			:max-width="snackbar.maxWidth"
			:multi-line="snackbar.multiline"
			:vertical="snackbar.vertical"
			:value="snackbar.value"
			@input="showSnackbar">
			{{ snackbar.text }}
			<template #action="{ attrs }">
				<v-btn
					v-bind="attrs"
					small outlined text icon
					@click="showSnackbar(false)">
					<v-icon small>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
		<v-navigation-drawer absolute right width="420" temporary v-model="drawerVisible">
			<field-edit-drawer
				v-if="drawerComponent"
				:drawer-closed="!drawerVisible"
				@keep-drawer-open="drawerVisible = true" />
		</v-navigation-drawer>
		<v-app-bar v-if="currentUser" app flat class="white">
			<v-container fluid class="pa-0 fill-height">
				<v-row no-gutters align="center" class="fill-height" v-if="portal">
					<v-col class="fill-height blue-grey darken-2 portal-container" cols="auto">
						<div class="d-flex align-center justify-center text-h6 portal-logo-title fill-height px-2">
							<div class="mr-2">
								<v-tooltip bottom>
									<template #activator="{ on, attrs }">
										<v-img
											v-bind="attrs"
											v-on="on"
											src="cm-logo-cooper-black.png"
											contain />
									</template>
									<span>version: {{ version }}</span>
								</v-tooltip>
							</div>
							<span class="text-no-wrap font-weight-bold blue-grey--text text--lighten-5">{{ portal.title }}</span>
							<span class="font-weight-light blue-grey--text text--lighten-5">Portal</span>
						</div>
					</v-col>
					<v-col cols="auto" class="pl-3">
						<solo-tab v-if="isSoloTab" />
						<v-tabs v-else
							v-model="portalTab"
							background-color="transparent"
							slider-color="blue-grey"
							color="blue-grey darken-2"
							class="animate__animated animate__fadeInDown"
							grow>
							<v-tab
								v-for="(tab, index) in portalTabs" :key="index"
								@click="gotoRoute(tab)"
								:href="`#${tab.name}`"
								class="px-6 cm-tab-text"
								:disabled="isTabDisabled(tab)">
								<v-badge
									color="red darken-3"
									dot
									:value="showNoticeAlert && tab.text === 'Notices'">
									<div v-html="tab.text" />
								</v-badge>
							</v-tab>
						</v-tabs>
					</v-col>
					<v-col cols="auto ml-auto pr-3 d-flex align-center">
						<v-tooltip bottom>
							<template #activator="{ on, attrs }">
								<v-btn
									v-bind="attrs" v-on="on"
									@click.stop="accountDetails"
									outlined fab
									:small="largeScreenSize"
									:x-small="!largeScreenSize">
									<v-icon>mdi-logout</v-icon>
								</v-btn>
							</template>
							<span>Logout</span>
						</v-tooltip>
						<div class="d-none d-md-flex flex-column greetings-font ml-3">
							<div>
								<span class="font-weight-light">Greetings, </span>
								<span class="font-weight-bold">{{ currentUser.Name }}</span>
							</div>
							<span class="company-font">{{ currentCompany.name }}</span>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-app-bar>
		<v-dialog
			v-model="showTestDataDialog"
			v-hotkey="keymap"
			max-width="900">
			<dialog-contents
				title="Test Data Setup"
				icon="mdi-database-edit"
				close-button
				@close="showTestDataDialog = false"
				:farLeftButton="{
					text: 'Clear Storage',
					click: clearStorage
				}"
				:leftButton="{
					text: 'Reset to Default',
					click: resetToDefault
				}"
				:rightButton="{
					text: 'Apply',
					click: () => {
						clearStorage({ applyTestDataLimits: true })
					}
				}">
				<v-container class="pa-0">
					<v-row no-gutters>
						<v-col cols="6" v-for="(limit) in localTestDataLimits" :key="limit.id">
							<v-card-text class="pb-1">
								<v-subheader class="text-subtitle-1 px-0 mt-3" style="height: 32px;">{{ limit.text }}</v-subheader>
								<v-range-slider
									v-model="limit.range"
									:min="limit.min"
									:max="limit.max"
									hide-details
									class="align-center blue-grey lighten-5 px-3 pb-2">
									<template #prepend>
										<v-text-field
											:value="limit.range[0]"
											hide-details
											label="min"
											type="number"
											style="width: 60px;"
											@change="$set(limit.range, 0, $event)" />
									</template>
									<template #append>
										<v-text-field
											:value="limit.range[1]"
											hide-details
											label="max"
											type="number"
											style="width: 60px;"
											@change="$set(limit.range, 1, $event)" />
									</template>
								</v-range-slider>
							</v-card-text>
						</v-col>
					</v-row>
				</v-container>
			</dialog-contents>
		</v-dialog>
		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '@/store/userStore'
import { useCraftsMasterStore } from '@/store/craftsMasterStore'
import { useCraftsmanStore } from '@/store/craftsmanStore'
import { useVendorStore } from '@/store/vendorStore'
import { useEmployerStore } from '@/store/employerStore'
import { useJobOrderStore } from '@/store/jobOrderStore'
import { useNoticeStore } from '@/store/noticeStore'
import CraftsMaster, { Portals } from '@/common/craftsMasterResolver'
import User from '@/common/userResolver'
import moment from 'moment'
// import VueRouter from 'vue-router'

// const { isNavigationFailure, NavigationFailureType } = VueRouter

export default {
	name: 'App',
	data: () => ({
		userType: User.UserType,
		drawerVisible: false,
		showTestDataDialog: false,
		localTestDataLimits: [],
		version: CraftsMaster.Version,
		portalTab: ''
	}),
	async created () {
		if (CraftsMaster.DataSource !== 'remote') {
			await this.setTestDataLimits()
			this.localTestDataLimits = JSON.parse(JSON.stringify(this.testDataLimits))
		}
		const tab = CraftsMaster.getTabByRouteName(this.$route.name)
		if (tab) {
			this.portalTab = tab.name
		}
	},
	watch: {
		drawerComponent (val) {
			this.drawerVisible = !!val
		},
		currentUserId (val) {
			if (val && this.portal) {
				this.setTabs(this.portal.tabs)
			}
		},
		'$route' (val) {
			if (val.name === 'login') {
				return
			}
			let tabs = []
			const tab = CraftsMaster.getTabByRouteName(val.name)

			if (tab) {
				this.portalTab = tab.name
			}

			if (tab?.solo) {
				tabs = [tab]
			} else {
				const portal = Portals.find(p => {
					const index = val.path.indexOf(p.name)
					return index === 1
				})
				if (portal) {
					tabs = portal.tabs
				}
			}
			const params = { ...val.params }
			if (Object.keys(params).length) {
				const name = val.name
				const tab = tabs.find(t => t.name === name)
				if (tab) {
					tab.params = params
				}
			}
			this.setTabs(tabs, tab)
		}
	},
	computed: {
		...mapState(useUserStore, [
			'currentUserId',
			'currentUser',
			'currentCompany',
			'portal'
		]),
		...mapState(useCraftsMasterStore, [
			'drawerComponent',
			'loading',
			'snackbar',
			'testDataLimits',
			'tabs'
		]),
		...mapState(useCraftsmanStore, ['craftsmen']),
		...mapState(useVendorStore, ['vendors']),
		...mapState(useEmployerStore, ['employers']),
		...mapState(useNoticeStore, [
			'noticeCenter',
			'noticeCenterVisited'
		]),
		portalTabs () {
			return this.tabs.filter(t => !t.solo)
		},
		keymap () {
			return {
				'alt+shift+d': this.toggleTestDataDialog
			}
		},
		largeScreenSize () {
			const screenSize = this.$vuetify.breakpoint.name
			return screenSize === 'lg' || screenSize === 'xl'
		},
		isSoloTab () {
			return this.tabs.length === 1 && this.tabs[0].solo
		},
		showNoticeAlert () {
			let showNoticeAlert = false
			if (this.noticeCenter.length > 0 && !this.noticeCenterVisited) {
				const today = moment()
				const latestNotice = this.noticeCenter[0]
				if (moment(latestNotice.createdAt).isSame(today, 'day')) {
					showNoticeAlert = true
				}
			}
			return showNoticeAlert
		}
	},
	methods: {
		...mapActions(useUserStore, ['logout']),
		...mapActions(useNoticeStore, ['setNoticeCenterVisited']),
		...mapActions(useCraftsMasterStore, [
			'setDrawerComponent',
			'showSnackbar',
			'setTestDataLimits',
			'setLoading',
			'setTabs'
		]),
		...mapActions(useJobOrderStore, ['clearJobOrders']),
		// ...mapActions(useEmployerStore, ['clearEmployers', 'getTestEmployers']),
		// ...mapActions(useVendorStore, ['clearVendors', 'getTestVendors']),
		...mapActions(useCraftsmanStore, ['clearCraftsmen']),
		isTabDisabled (tab) {
			return (tab.name === 'craftsmen' && this.craftsmen.length === 0) || tab.disabled
		},
		toggleTestDataDialog () {
			this.showTestDataDialog = CraftsMaster.DataSource !== 'remote' && !this.showTestDataDialog
		},
		accountDetails () {
			// TODO: just logging out for now and returning to login
			this.logout()
			this.$router.push('/')
		},
		resetToDefault () {
			this.localTestDataLimits = JSON.parse(JSON.stringify(CraftsMaster.DefaultTestDataLimits))
		},
		clearStorage (options) {
			options = options || {
				applyTestDataLimits: false
			}
			localStorage.clear()
			// this.clearJobOrders()
			// this.clearCraftsmen()
			// this.clearVendors()
			// this.clearEmployers()

			// this.logout()
			if (options.applyTestDataLimits) {
				this.setTestDataLimits(this.localTestDataLimits)

				this.showTestDataDialog = false

				// this.setLoading(true)

				// setTimeout needed to ensure dialog hides and loading spinner displays
				// setTimeout(async () => {
				// 	await this.getTestEmployers()
				// 	await this.getTestVendors()

				// 	if (this.$route.name !== 'login') {
				// 		this.$router
				// 			.push('/')
				// 			.then(() => {
				// 				this.setLoading(false)
				// 			}).catch(failure => {
				// 				if (isNavigationFailure(failure, NavigationFailureType.cancelled)) {
				// 					// do nothing
				// 				}
				// 			})
				// 	} else {
				// 		setTimeout(() => {
				// 			this.setLoading(false)
				// 		})
				// 	}
				// })
			}
		},
		// "to" attribute on v-tab causes "missing param for named route" console warning
		gotoRoute (tab) {
			if (tab.text === 'Notices') {
				this.setNoticeCenterVisited(true)
			}
			this.$router.push({
				name: tab.name,
				params: tab.params
			})
		}
	}
}
</script>
<style lang="scss">
@use './styles/cm';

html,
body {
	overflow: hidden !important;
}

.cm-text {
	max-height: 64px;
	overflow: hidden;
	.red--text {
		font-size: 0.975rem !important;
	}
	font-size: 0.875rem;
	.text-caption {
		font-size: 0.7rem !important;
	}

	@media screen and (max-width: 1600px) {
		.red--text {
			font-size: 0.875rem !important;
		}
		.text-caption {
			font-size: 0.6rem !important;
		}
		font-size: 0.75rem;
	}

	@media screen and (max-width: 1380px) {
		.red--text {
			font-size: 0.75rem !important;
		}
		.text-caption {
			font-size: 0.55rem !important;
		}
		font-size: 0.68rem;
	}

	// @media screen and (max-width: 1264px) {
	// 	.text-caption {
	// 		font-size: 0.5rem !important;
	// 	}
	// 	font-size: 0.6rem;
	// }
}
.cm-tab-text {
	@extend .cm-text;

	@media screen and (max-width: 1600px) {
		max-width: 200px;
	}

	@media screen and (max-width: 1380px) {
		max-width: 185px;
	}

	// @media screen and (max-width: 1264px) {
	// 	max-width: 175px;
	// }
}

.v-toolbar__content {
	padding: 0 !important;
}

.right-border {
	border-right: solid 1px #bdbdbd;
}

.greetings-font {
	font-size: 0.875rem;
	.company-font {
		font-size: 0.75rem;
		font-weight: 400;
	}

	@media screen and (max-width: 1264px) {
		font-size: 0.75rem;
		.company-font {
			font-size: 0.68rem;
		}
	}

	@media screen and (max-width: 980px) {
		font-size: 0.68rem;
		.company-font {
			font-size: 0.6rem;
		}
	}
}
// fixes vuetify bug with keep-alive inside hide-on-leave transition
// .portal-container {
// 	display: flex !important;
// }
</style>
<style lang="scss" scoped>
.portal-logo-title {
	font-size: 1.45rem !important;
	.v-image {
		width: 50px;
		height: 50px;
	}

	@media screen and (max-width: 1400px) {
		font-size: 1.25rem !important;
		.v-image {
			width: 40px;
			height: 40px;
		}
	}

	@media screen and (max-width: 1264px) {
		font-size: 1rem !important;
		.v-image {
			width: 30px;
			height: 30px;
		}
	}

	@media screen and (max-width: 920px) {
		font-size: 0.8rem !important;
		.v-image {
			width: 25px;
			height: 25px;
		}
	}
}

.portal-container {
	width: 244px;

	@media screen and (max-width: 1400px) {
		width: unset;
	}
}
</style>
